import React, { useState, useEffect } from 'react';
import { FooterContainer, FooterBlock, FooterAd, FooterJournalArticle } from '../../Footer';
import { NoProductsBlockContainer, NoProductsBlock } from '../../NoProducts';
import { ProductReplacement, ProductReplacementContent, ProductReplacementAd } from '../../../product-replacement';
import SaleBanner from '@ycos/component-sale-banner';
import ContentCarousel from '../../ContentCarousel/ContentCarousel';
import { CoremediaRenderer, getContentByLayoutVariant } from '@ycos/coremedia-renderer';

const CoremediaContent = ({ data, rules = () => {} }) => {
    if (!data) return null;
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const content = mapContent(data);

    useEffect(() => {
        if (window && content.eip) {
            window.SF?.getUserSummary?.()
                .then((user) => {
                    setUser(user);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, []);
    const getContentByUserType = ({ eip, nonEip }) => {
        if (eip && !loading && user?.isEip) {
            return eip;
        }
        return nonEip;
    };

    return (
        <>
            {CoremediaRenderer(
                getContentByUserType(content),
                [
                    NoProductsBlockContainer,
                    NoProductsBlock,
                    FooterContainer,
                    FooterBlock,
                    FooterAd,
                    FooterJournalArticle,
                    ProductReplacement,
                    ProductReplacementContent,
                    ProductReplacementAd,
                    ContentCarousel,
                    SaleBanner,
                ],
                {
                    rules: [
                        ({ item }) => ({
                            ...item,
                            loading: content.eip && loading,
                        }),
                        rules,
                    ],
                },
            )}
        </>
    );
};

export const mapContent = (data) => {
    if (Array.isArray(data)) {
        if (!data.length > 1) {
            return {
                nonEip: data[0],
            };
        }
        const content = data.reduce((accum, current) => {
            const eipContent = getEipContent(current);
            return {
                ...accum,
                ...(!eipContent && { nonEip: current }),
                ...(eipContent && { eip: eipContent }),
            };
        }, {});
        return content;
    }
    return {
        nonEip: data,
    };
};

export const getEipContent = (item = {}) => {
    if (!item.tags) return;
    const eipTag = item.tags.find(({ name, children }) => name === 'customertype' && children.find(({ name }) => name === 'eip'));
    if (eipTag) {
        return item;
    }
};

export const hasSaleContent = (content) =>
    SaleBanner.layoutVariant.reduce((arr, layoutVariant) => {
        if (content) {
            const match = getContentByLayoutVariant([content], layoutVariant);
            return match && match.length > 0 ? match : arr;
        }
        return arr;
    }, []);

export default CoremediaContent;
