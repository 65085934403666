import { safeBuildEventObj } from '@ynap/analytics';
import { getCategory } from '../../../analytics/plp';
const mapDesignerList = (subCategory) => (subCategory === 'designer list' ? 'designer a-z' : subCategory);

const _trackGoToDesignerPage = (analytics, designerName) => {
    const { page = {} } = analytics;

    return {
        event: 'designer a-z - go to designer',
        effect: 'navigate',
        attributes: {
            navDetails: designerName,
        },
        category: {
            primaryCategory: page?.category?.pageType,
            subCategory: mapDesignerList(page?.category?.subCategory1),
        },
    };
};

const _trackGoToLetter = (analytics, letter) => {
    const { page = {} } = analytics;
    return {
        event: `${mapDesignerList(page.category.subCategory1)} - go to letter ${letter}`,
        effect: 'anchor',
        category: {
            primaryCategory: page?.category?.pageType,
            subCategory: mapDesignerList(page?.category?.subCategory1),
        },
    };
};

const _trackChangeFavouriteDesigner = (analytics, attributes) => {
    const [designer, isFavourite] = attributes;
    const action = isFavourite ? 'remove' : 'add';
    const { page = {}, pageType, categoryLabels } = analytics;
    const category = page?.category || getCategory(categoryLabels, pageType);

    return {
        event: `${action} favourite designer`,
        effect: `${action == 'remove' ? 'un ' : ''}toggle designer`,
        attributes: {
            position: designer,
        },
        category: {
            primaryCategory: category?.pageType,
            subCategory: mapDesignerList(category?.subCategory1),
        },
    };
};

/* istanbul ignore next */
const _trackFavouriteDesignerSignIn = (analytics, attributes) => {
    const [designer, action] = attributes;
    const { page = {}, pageType, categoryLabels } = analytics;
    const category = page?.category || getCategory(categoryLabels, pageType);

    let event,
        effect = '';

    switch (action) {
        case 'SIGN_IN_DISPLAY':
            event = 'favourite designer - sign in';
            effect = 'display sign in prompt';
            break;
        case 'SIGN_IN_CLOSE':
            event = 'favourite designer - sign in dismiss';
            effect = 'remove sign in prompt';
            break;
        case 'SIGN_IN_ACCEPT':
            event = 'favourite designer - sign in accept';
            effect = 'go to log in page';
            break;
        default:
    }

    return {
        event,
        effect,
        attributes: {
            position: designer,
        },
        category: {
            primaryCategory: category?.pageType,
            subCategory: mapDesignerList(category?.subCategory1),
        },
    };
};

/* istanbul ignore next */
const _trackFavouriteDesignerOnboarding = (analytics, attributes) => {
    const [action] = attributes;
    const { page = {}, pageType, categoryLabels } = analytics;
    const category = page?.category || getCategory(categoryLabels, pageType);

    let event,
        effect = '';

    switch (action) {
        case 'ONBOARDING_DISPLAY':
            event = 'favourite designer - onboarding';
            effect = 'display onboarding';
            break;
        case 'ONBOARDING_CLOSE':
            event = 'favourite designer - onboarding dismiss';
            effect = 'close onboarding';
            break;
        case 'ONBOARDING_CONFIRM':
            event = 'favourite designer - onboarding opt in';
            effect = 'close onboarding';
            break;
        default:
    }

    return {
        event,
        effect,
        category: {
            primaryCategory: category?.pageType,
            subCategory: mapDesignerList(category?.subCategory1),
        },
    };
};

export const trackGoToDesignerPage = (analytics = {}, designerName) => safeBuildEventObj(_trackGoToDesignerPage, analytics, designerName);
export const trackGoToLetter = (analytics = {}, letter) => safeBuildEventObj(_trackGoToLetter, analytics, letter);
export const trackChangeFavouriteDesigner = (analytics = {}, attributes) => safeBuildEventObj(_trackChangeFavouriteDesigner, analytics, attributes);
export const trackFavouriteDesignerSignIn = (analytics = {}, attributes) => safeBuildEventObj(_trackFavouriteDesignerSignIn, analytics, attributes);
export const trackFavouriteDesignerOnboarding = (analytics = {}, attributes) => safeBuildEventObj(_trackFavouriteDesignerOnboarding, analytics, attributes);
