import React, { PureComponent } from 'react';
import { func, object, shape, bool } from 'prop-types';
import Filter from '../../Filter/Filter';

class SingleSelectFilterGroup extends PureComponent {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            selectedCategory: '',
        };
    }
    static propTypes = {
        filter: object.isRequired,
        generateListingUrl: func.isRequired,
        messages: shape({
            productListing: shape({
                allSelected: func.isRequired,
            }).isRequired,
        }),
        onClick: func,
        trackFilterEvent: func.isRequired,
        filterBySeoPath: bool.isRequired,
        clearAllParamsOnAll: bool.isRequired,
    };

    handleClick(category) {
        this.setState({ selectedCategory: category });
        this.props.onClick?.();
    }

    componentDidUpdate(prevProps) {
        if (this.props.filter.appliedLabels.length < prevProps.filter.appliedLabels.length) {
            this.setState({ selectedCategory: '' });
        }
    }

    render() {
        const { filter, messages, generateListingUrl, trackFilterEvent, filterBySeoPath, clearAllParamsOnAll, useIdentifier } = this.props;
        const parentFilterValue = useIdentifier ? filter.id : filter.label;
        const filters = filter.values.map((filterValue) => (
            <Filter
                filter={filterValue}
                link={
                    filterBySeoPath
                        ? generateListingUrl({
                              pathname: filterValue.value.toLowerCase(),
                              relativeToBasePath: true,
                              preserveParamsByKey: ['orderBy'],
                              clearParamsByKey: ['pageNumber', 'facet', 'addCategory'],
                          })
                        : generateListingUrl({
                              ...(filter.id === 'CATEGORIES' && { replaceParams: { ['categoryId']: filterValue.categoryId } }),
                              ...(filter.queryKey === 'addCategoryId' && {
                                  replaceParams: { ['addCategoryId']: filterValue.categoryId },
                                  preserveParamsByKey: ['categoryId'],
                              }),
                              ...(filter.queryKey === 'categoryId' &&
                                  filter.id !== 'CATEGORIES' && { replaceParams: { ['addCategoryId']: filterValue.categoryId } }),
                              clearParamsByKey: ['pageNumber', filter.id === 'CATEGORIES' && 'addCategoryId'],
                          })
                }
                key={filterValue.label}
                className="radioButton"
                trackFilterEvent={trackFilterEvent}
                parentLabel={parentFilterValue}
                onClick={this.handleClick}
                selectedCategory={this.state.selectedCategory}
            />
        ));

        const selectAll = {
            label: messages.productListing.allSelected(),
            isApplied: filter.appliedValues.length === 0,
        };
        return (
            <>
                {!filter.hideAllSelect && (
                    <Filter
                        className="radioButton"
                        filter={selectAll}
                        link={
                            filterBySeoPath
                                ? generateListingUrl({
                                      pathname: filter.value,
                                      relativeToBasePath: true,
                                      preserveParamsByKey: ['orderBy'],
                                      clearParamsByKey: ['pageNumber', 'facet', 'addCategory'],
                                  })
                                : generateListingUrl({
                                      clearParamsByKey: ['pageNumber', 'addCategoryId', clearAllParamsOnAll && 'categoryId'],
                                      ...(filter.queryKey === 'addCategoryId' && { preserveParamsByKey: ['categoryId'] }),
                                  })
                        }
                        trackFilterEvent={() => trackFilterEvent({ label: 'unselect all', identifier: filter.id }, parentFilterValue)}
                        onClick={this.handleClick}
                        selectedCategory={this.state.selectedCategory}
                    />
                )}
                {filters}
            </>
        );
    }
}

export default SingleSelectFilterGroup;
