import React, { PureComponent } from 'react';
import { func, object, string, bool } from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { connect } from 'react-redux';
import { Link } from '@ynap/router';
import Swatch from '@ynap/swatch';
import { performance } from '@ynap/clientside-monitoring';
import { withOptimizely } from '@ynap/optimizely-utils';
import { plpActions } from '../../../../redux/product-listing-state';
const { setMultiFilter, removeMultiFilter, removeSingleFilter, setSingleFilter } = plpActions;

const bem = new BEMHelper('Filter');

class Filter extends PureComponent {
    static propTypes = {
        filter: object.isRequired,
        type: string,
        link: string,
        className: string,
        onClick: func,
        trackFilterEvent: func.isRequired,
        parentLabel: string,
        subcategoryLabel: string,
        isLatinOnly: bool,
    };

    render() {
        const {
            filter,
            className,
            link,
            onClick,
            trackFilterEvent,
            parentLabel,
            subcategoryLabel,
            optimizely,
            selectedCategory,
            multiSelectedCategory,
            unSelectFilter,
            selectFilter,
            unSelectSingleFilter,
            selectSingleFilter,
        } = this.props;
        let selected = filter.isApplied ? 'selected' : null;

        if (typeof selectedCategory === 'string') {
            if (selectedCategory && filter.isApplied === false) {
                selected = selectedCategory === filter.label ? 'selected' : null;
            }
        }

        if (Array.isArray(multiSelectedCategory)) {
            if (multiSelectedCategory?.length && filter.isApplied === false) {
                selected = multiSelectedCategory.includes(filter.label) ? 'selected' : null;
            }
        }
        const clickHandler = () => {
            if (filter.isApplied && multiSelectedCategory) {
                unSelectFilter(filter);
            } else if (multiSelectedCategory) {
                selectFilter(filter);
            } else {
                unSelectSingleFilter(filter);
                selectSingleFilter(filter);
            }
            optimizely?.track?.('plp_filter_clicks');
            performance.intTiming.markSpaInteractionTime('filter');
            typeof onClick === 'function' && onClick(filter.label, filter);
            return subcategoryLabel ? trackFilterEvent(filter, parentLabel, subcategoryLabel) : trackFilterEvent(filter, parentLabel);
        };

        return (
            <Link className={bem('filterLine')} to={link} onClick={clickHandler}>
                <span className={bem('filterLineCheckbox', selected, className)} />
                {filter.swatch && <Swatch className={bem('swatch')} colourClass={'image'} colour={filter.swatch.hex} url={filter.swatch.url} />}
                <span className={bem('filterLineLabel', null)}>{filter.label}</span>
            </Link>
        );
    }
}

export const mapDispatchToProps = (dispatch) => ({
    selectFilter: (filter) => dispatch(setMultiFilter(filter)),
    unSelectFilter: (filter) => dispatch(removeMultiFilter(filter)),
    unSelectSingleFilter: (filter) => dispatch(removeSingleFilter(filter)),
    selectSingleFilter: (filter) => dispatch(setSingleFilter(filter)),
});

export default connect(null, mapDispatchToProps)(withOptimizely(Filter));
