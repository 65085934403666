/* istanbul ignore file */
import { useCallback, useEffect, useState } from 'react';
import { useConfig, useAppState } from '@ynap/immutables-provider';
import { productSelectors } from '@ynap/product-state';
import { getDetailsByPartNumbers } from '@ynap/api-utils/src/catalogue';

const MAX_RECOMMENDATIONS = 16;

/**
 * Monetate is a third party that provides YMAL recommendations for TON
 *
 * Their only offering for integration at the moment (28/01/2021) is by
 * their client library invoking a function on `window` with the
 * recommendations for the current product, all of which is backed with
 * tracking logic provided in GTM.
 *
 * Because the recommendations may have been populated before the carousel
 * has been rendered, we store the result on `window` which the hook uses
 * as the initial state. We also dispatch an event for the case where
 * recommendations are populated after the carousel has rendered which is
 * handled via an effect.
 */

typeof window !== 'undefined' &&
    (() => {
        window.monetateRecommendation = (identifier, actionId, recommendations) => {
            if (!recommendations?.length) {
                return;
            }
            let mappedRecommendations = recommendations.map((recommendation) => {
                recommendation.actionId = actionId;
                return recommendation;
            });
            if (identifier === 'Emptysearch - trending') {
                window.emptySearchRecommendations = mappedRecommendations;
                return window.dispatchEvent(new Event(emptySearchRecommendationEventName));
            }
        };
    })();

const { getMappedProduct } = productSelectors;

const configSelector = (config) => ({
    baseUrl: config.global.services.commerce.baseUrl,
    brandId: config.global.application.brandId,
    clientId: config.global.services.commerce.clientId,
    availableImageSizes: config.head.availableImageSizes,
    imageQuality: config.head.imageQualityPercentage,
    pdpUrlTemplate: config.global.urlConfig.pdp.construct,
});

const emptySearchRecommendationEventName = 'emptySearchRecommendations';

export const useRecommendations = () => {
    let recommendations = null;
    const { baseUrl, clientId, brandId, pdpUrlTemplate, availableImageSizes, imageQuality } = useConfig(configSelector);
    const { country, locale, langISO } = useAppState();
    const { emptySearchRecommendations } = typeof window !== 'undefined' ? window : {};
    const [externalEmptySearch, setExternalEmptySearch] = useState(emptySearchRecommendations);
    const [emptySearch, setEmptySearch] = useState();

    const toRecommendedProduct = useCallback((product, trackingAttributes) => {
        const { imgSrc, designerName, name, partNumber, href, price, badge, analyticsAttributes } = getMappedProduct({
            id: product.productId,
            product,
            badgeBlocklist: [],
            locale,
            availableImageSizes,
            pdpUrlTemplate,
            imageQuality,
            pageType: '',
            messages: {},
        });

        return {
            imgSrc,
            designerName,
            name,
            partNumber,
            href,
            price,
            badge,
            analyticsAttributes,
            trackingAttributes,
        };
    }, []);

    useEffect(() => {
        const emptySearchRecommendationHandler = () => setExternalEmptySearch(window.emptySearchRecommendations);

        window.addEventListener(emptySearchRecommendationEventName, emptySearchRecommendationHandler);

        return () => {
            delete window.emptySearchRecommendations;
            window.removeEventListener(emptySearchRecommendationEventName, emptySearchRecommendationHandler);
        };
    }, []);

    useEffect(() => {
        if (externalEmptySearch) {
            fetchRecommendations(externalEmptySearch, setEmptySearch);
        }
    }, [externalEmptySearch, setEmptySearch]);

    const fetchRecommendations = (externalRecommendation, setRecommendation) => {
        const partNumbers = [];
        const trackingAttributes = {};

        if (externalRecommendation?.length > MAX_RECOMMENDATIONS) {
            externalRecommendation.splice(16);
        }

        externalRecommendation.forEach(({ itemGroupId, id, actionId }) => {
            partNumbers.push(itemGroupId);
            trackingAttributes[itemGroupId] = { itemGroupId, id, actionId };
        });

        const request = getDetailsByPartNumbers({
            baseUrl,
            brandId,
            country,
            partNumbers,
            clientId,
            options: { locale: langISO },
        });

        fetch(request)
            .then((response) => response.json())
            .then((json) => json.products.map((product) => toRecommendedProduct(product, trackingAttributes[product.partNumber])))
            .then(setRecommendation)
            // eslint-disable-next-line no-console
            .catch(console.error);
    };

    if (emptySearch && !recommendations) {
        recommendations = emptySearch;
    }

    return recommendations;
};
