export const trackSaleToggle = (copy, url) => {
    return {
        event: `list page nav - click`,
        category: {
            primaryCategory: `list page click`,
            subCategory: copy,
        },
        attributes: {
            targetLink: copy,
            targetURL: url,
        },
    };
};

export const trackRefine = (analytics = { page: {} }, modifier) => {
    const { page } = analytics;
    return {
        event: `list page - ${modifier} filter box`,
        eventEffect: 'flyout',
        category: {
            primaryCategory: page?.category?.pageType,
            subCategory: page?.category?.subCategory1,
        },
    };
};

export const trackFilter = (
    analytics = { page: {} },
    { identifier, label, value = '' },
    type = null,
    subType = null,
    useIdentifier = false,
    position = 'filter box',
) => {
    const { page } = analytics;

    return {
        event: 'list page nav - filter update',
        eventEffect: 'filter',
        category: {
            primaryCategory: page?.category?.pageType,
            subCategory: page?.category?.subCategory1,
        },
        attributes: {
            label: useIdentifier && identifier ? identifier : label,
            id: identifier || value,
            type: type,
            ...(subType && { subType }),
            ...(label == 'Price' && {
                valueMin: subType[0],
                valueMax: subType[1],
            }),
            position,
        },
    };
};

export const trackFavorites = (analytics = { page: {} }) => {
    const { page } = analytics;
    return {
        event: 'list page nav - your favourites',
        eventEffect: 'filter',
        category: {
            primaryCategory: page?.category?.pageType,
            subCategory: page?.category?.subCategory2,
        },
        attributes: {
            id: '',
            label: 'Designer',
            type: 'favourite designers',
        },
    };
};

export const trackFilterToggle = (analytics = { page: {} }, { identifier, label }, type = null, toggleStatus) => {
    const { page } = analytics;
    return {
        event: `list page nav - toggle update`,
        eventEffect: `toggle select all - ${toggleStatus}`,
        category: {
            primaryCategory: page?.category?.pageType,
            subCategory: page?.category?.subCategory1,
        },
        attributes: {
            label: label,
            id: identifier,
            type: type,
        },
    };
};

export const trackPagination = (analytics = { page: {} }, value = '') => {
    const { category = {} } = analytics.page;
    return {
        event: `pagination - ${value}`,
        eventEffect: 'pagination',
        category: {
            primaryCategory: category.pageType,
            subCategory: category.subCategory1,
        },
        attributes: {
            value,
        },
    };
};

export const trackLoadMore = (analytics = { page: {} }, value = '', currentPage = 1) => {
    const { category = {} } = analytics.page;
    return {
        event: `pagination - ${value}`,
        eventEffect: `pagination - ${value}`,
        category: {
            primaryCategory: category.pageType,
            subCategory: category.subCategory1,
        },
        attributes: {
            value,
            pageNumber: currentPage,
        },
    };
};

export const trackBackToTop = (analytics = { page: {} }, currentPage = 1) => {
    const { category = {} } = analytics.page;
    return {
        event: 'back to top',
        eventEffect: 'back to top arrow clicked',
        category: {
            primaryCategory: 'listing page',
            subCategory: category.subCategory1,
        },
        attributes: {
            pageNumber: currentPage,
        },
    };
};

export const trackDesignersSearchFocus = (analytics = { page: {} }) => {
    const { category = {} } = analytics.page;
    return {
        event: 'designers search filter - focus',
        eventEffect: 'filter input in focus',
        category: {
            primaryCategory: category?.pageType,
            subCategory: category.subCategory1,
        },
    };
};

export const trackDesignersSearchEntry = (analytics = { page: {} }) => {
    const { category = {} } = analytics.page;
    return {
        event: 'designers search filter - start',
        eventEffect: 'character typed',
        category: {
            primaryCategory: category?.pageType,
            subCategory: category.subCategory1,
        },
    };
};
