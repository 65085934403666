import React from 'react';
import { PropTypes } from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import Skeleton from '@ynap/skeleton';
import { getConfigByLayoutVariant, showInPage } from '../helper/helper';

const bem = new BEMHelper('ProductReplacement');

const getTotalOfProductReplacements = (content) => {
    if (content?.stickyTeaser) {
        return content?.stickyTeaser?.reduce((accumulator, currentValue) => {
            const { item } = currentValue;
            const { numOfPids } = getConfigByLayoutVariant(item) || 0;
            return accumulator + numOfPids;
        }, 0);
    }
};

/* TODO: orphan PIDs */
const ProductReplacement = ({ children = [], pageNumber, enableLoadMore, parent, item, numOfProducts, maxNumOfColumns = 4, loading }) => {
    const notRender = showInPage(children[0], item, pageNumber, enableLoadMore);
    const position = item.position;
    const numOfTotalProductReplacements = getTotalOfProductReplacements(parent);
    const numOfRowsAvailable = (numOfProducts + numOfTotalProductReplacements) / maxNumOfColumns; // PLP maximum number of columns is 4 in tablet
    // Do not render when position is not available or when there are not enough products in the listing page
    if (!position || numOfRowsAvailable < position) return null;
    const { layoutType, modifier = [] } = getConfigByLayoutVariant(item) || {};
    return (
        <>
            {children && !notRender && (
                <div className={bem(layoutType, modifier)} style={{ gridRow: `${position}` }}>
                    {loading ? <ProductReplacementSkeleton /> : children}
                </div>
            )}
        </>
    );
};

export const ProductReplacementSkeleton = () => (
    <div className={bem('skeleton-container')}>
        <Skeleton align="left" containerClassName={bem('skeleton-image-container')} className={bem('skeleton-image')} />
        <Skeleton align="left" count={3} />
    </div>
);

ProductReplacement.layoutVariant = [
    'yos-plp-product-replacement-single',
    'yos-plp-product-replacement-article',
    'yos-plp-product-replacement-double',
    'yos-plp-product-replacement-row',
];

ProductReplacement.propTypes = {
    parent: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    children: PropTypes.object,
    numOfProducts: PropTypes.number.isRequired,
    maxNumOfColumns: PropTypes.number,
    pageNumber: PropTypes.number,
    enableLoadMore: PropTypes.bool,
};

export default ProductReplacement;
