import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { BackgroundPicture } from '@ycos/picture';
import { useConfig } from '@ynap/immutables-provider';
import { ValueTransition } from '@ynap/value-transition';
import { getTagValue, hasTag } from '@ycos/coremedia-renderer';
import { ratioToPadding } from '@ynap/fela';
import { linkRenderer } from '@ynap/router';

const bem = new BEMHelper('FooterJournalArticle');

function getReadLengthFromTag(tags) {
    const readLengthTag = hasTag(tags, 'Journal.Article-Length') ? getTagValue(tags, 'Journal.Article-Length') : '';
    return readLengthTag.replace('min', 'minute read').toUpperCase();
}

const checkIfImageRatioisValid = (image, ratio = '16:9', threshold = 0.01) => {
    const splitRatio = ratio.split(':');
    const ratioResult = splitRatio[1] / splitRatio[0];
    const { width, height } = image;
    const imageRatio = height / width;
    return ratioResult - imageRatio > -threshold && ratioResult - imageRatio < threshold;
};

const FooterJournalArticle = ({ item, imageQualityPercentage = 80, urlConfig, internal_urls, locale }) => {
    if (!item) return;

    const { target, href, anchorpoint = '' } = item;
    const { teaserTitle, tags, picturesAndMedia } = target;
    const topicName = getTagValue(tags, 'Journal.Topics');
    const readLength = getReadLengthFromTag(tags);
    const {
        head: { aspectRatio: { articleImage, articleImagePhone } = {} },
    } = useConfig();

    const orderedPictureArray = [
        picturesAndMedia.find((picture) => checkIfImageRatioisValid(picture, '4:5')),
        picturesAndMedia.find((picture) => checkIfImageRatioisValid(picture)),
    ];

    // Calculate aspect ratio as a decimal fraction for Picture component to * 100 for padding-top hack
    const forceAspectRatioDesktop = ratioToPadding(articleImage);
    const forceAspectRatioMobile = ratioToPadding(articleImagePhone);

    // height prop passed to BackgroundPicture is to prevent full height image in image test (which hides the text)
    const content = (
        <>
            <BackgroundPicture
                images={orderedPictureArray}
                forceAspectRatioDesktop={forceAspectRatioDesktop}
                forceAspectRatioMobile={forceAspectRatioMobile}
                imagesphere={{ quality: imageQualityPercentage }}
                additionalStyles={{ '& div': { height: 'auto' } }}
            />
            <div className={bem('content')}>
                <ValueTransition component="h2" className={bem('topic')}>
                    {topicName}
                </ValueTransition>
                <ValueTransition component="h3" className={bem('title')}>
                    {teaserTitle}
                </ValueTransition>
                <ValueTransition component="p" className={bem('read-length')}>
                    {readLength}
                </ValueTransition>
            </div>
        </>
    );

    const link = href && linkRenderer(urlConfig, internal_urls, locale, `${href}${anchorpoint}`, content, null, bem('link'));

    return <div className={bem()}>{link}</div>;
};

FooterJournalArticle.layoutVariant = ['yos-plp-footer-article'];

FooterJournalArticle.propTypes = {
    item: PropTypes.shape({
        target: PropTypes.shape({
            teaserTitle: PropTypes.string,
            tags: PropTypes.array,
            picturesAndMedia: PropTypes.array,
        }),
        href: PropTypes.string,
    }).isRequired,
};

export default FooterJournalArticle;
