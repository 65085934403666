//TODO: move this get errors utility to utils when error handling matures in the different heads

export function getErrors(states = {}) {
    let errors = {};

    for (const key in states) {
        const state = states[key];
        if (state) {
            const { error } = state;
            errors[key] = getErrorDetail(error);
        }
    }
    return errors;
}

export function getErrorDetail(error) {
    if (!error) {
        return null;
    }
    //for specific error from api response
    if (error.status && error.body?.error?.errors) {
        return error.body.error.errors && error.body.error.errors[0].errorCode;
    }
    //for general errors like parsing errror, network error
    if (error.body) {
        return error.body;
    }
    return error;
}
