import React, { PureComponent } from 'react';
import { func, object, shape, string } from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { Link } from '@ynap/router';
import Filter from '../../Filter/Filter';
import { Input } from '@ynap/field';
import TopDesignersFilter from './TopDesignersFilter';
import { moveToTop, getListHeight } from './stickySearchHelper';
import { normalizeStringHelper } from '@ynap/normalize-string-helper';
import FavouriteDesignerFilter from './FavouriteDesignerFilter';

const bem = new BEMHelper('DesignerFilterGroup');

class DesignerFilterGroup extends PureComponent {
    static propTypes = {
        filter: object.isRequired,
        generateListingUrl: func.isRequired,
        messages: shape({
            productListing: shape({
                unselectAll: func.isRequired,
            }).isRequired,
            topDesignersThisWeek: func,
            azDesigners: func,
            searchDesigner: func,
        }).isRequired,
        trackFilterEvent: func.isRequired,
        trackFavoritesEvent: func.isRequired,
        facetConfig: shape({
            numOfTopDesigners: string,
        }),
    };

    static defaultProps = {
        messages: {
            topDesignersThisWeek: () => '',
            azDesigners: () => '',
            searchDesigner: () => '',
        },
        facetConfig: {},
    };

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            values: [],
            searchTerm: '',
            designersListHeight: '',
            emptyTextFeild: true,
            clicked: true,
            multiSelectedCategory: [],
        };
    }

    componentDidUpdate(prevProps) {
        const { filter } = this.props;
        const { filter: prevFilter } = prevProps;

        if (prevFilter.appliedValues.length != filter.appliedValues.length) {
            const { searchTerm } = this.state;
            this.filterValues(searchTerm, filter.values);
        }
        if (filter.appliedLabels?.length < prevFilter.appliedLabels?.length) {
            this.setState({ multiSelectedCategory: [] });
        }
    }

    componentDidMount() {
        // Clear cache when user ticks checkbox, otherwise isApplied gets ignored
        this.setState({
            values: [],
        });
    }

    filterValues(searchTerm, valuesToFilter) {
        const normalizedSearchTerm = normalizeStringHelper(searchTerm);

        valuesToFilter = valuesToFilter.filter((item) => {
            if (!item.label) {
                return false;
            }
            const normalizedLabel = normalizeStringHelper(item.label);
            return normalizedLabel.toLowerCase().search(normalizedSearchTerm) !== -1;
        });

        this.setState({
            values: valuesToFilter,
            searchTerm: searchTerm,
        });
    }

    handleInputUpdate = (e, trackDesignersSearchEntry) => {
        let { value } = e.target;
        if (value) {
            value = value.toLowerCase();
        }
        let valuesToFilter = this.props.filter.values;
        this.filterValues(value, valuesToFilter);
        value.length === 1 && this.state.emptyTextFeild ? (this.setState({ emptyTextFeild: false }), trackDesignersSearchEntry()) : null;
        value.length === 0 && this.setState({ emptyTextFeild: true });
    };

    onInputCancelClick = () => {
        this.setState({
            values: this.props.filter.values,
            searchTerm: '',
        });
    };

    handleMouseDown = (e, trackDesignersSearchFocus) => {
        moveToTop(e.target);
        this.setState({
            designersListHeight: getListHeight(e.target),
        });
        trackDesignersSearchFocus();
    };

    handleClick(category) {
        const removeItem = () => {
            this.setState({ multiSelectedCategory: this.state.multiSelectedCategory.filter((value) => value !== category) });
        };

        this.state.multiSelectedCategory.includes(category)
            ? removeItem()
            : this.setState({ multiSelectedCategory: this.state.multiSelectedCategory.concat([category]) });
    }

    render() {
        const {
            filter,
            messages,
            generateListingUrl,
            trackFilterEvent,
            trackFavoritesEvent,
            facetConfig,
            trackDesignersSearchEntry,
            trackDesignersSearchFocus,
            useIdentifier,
        } = this.props;
        const { searchTerm, values, designersListHeight } = this.state;
        const parentFilterValue = useIdentifier ? filter.id : filter.label;

        const valuesToUse = searchTerm !== '' ? values : filter.values;

        const groupedValues = getGroupedValues(valuesToUse);

        const labeledFilters = Object.keys(groupedValues).map((label) => (
            <div key={label}>
                <label className={bem('keyLabel')}>{label}</label>
                {groupedValues[label]
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((filterValue) => (
                        <Filter
                            filter={filterValue}
                            link={generateListingUrl({
                                toggleParams: { [filter.queryKey]: filterValue.value },
                                clearParamsByKey: ['pageNumber'],
                            })}
                            className={'checkBox'}
                            key={filterValue.value}
                            trackFilterEvent={trackFilterEvent}
                            parentLabel={parentFilterValue}
                            onClick={this.handleClick}
                            multiSelectedCategory={this.state.multiSelectedCategory}
                        />
                    ))}
            </div>
        ));

        const disabled = filter.appliedValues.length === 0 ? 'disabled' : null;
        const numOfTopDesigners = facetConfig?.numOfTopDesigners;
        const listStyles = designersListHeight ? { minHeight: `${designersListHeight}px` } : {};

        return (
            <div className={bem()}>
                {labeledFilters.length !== 0 && (
                    <Link
                        className={bem('unselectAll', disabled)}
                        onClick={() => trackFilterEvent({ label: 'unselect all', identifier: filter.id }, parentFilterValue)}
                        to={generateListingUrl({ toggleParams: { [filter.queryKey]: filter.appliedValues }, clearParamsByKey: ['pageNumber'] })}
                    >
                        {messages.productListing.unselectAll()}
                    </Link>
                )}

                {numOfTopDesigners && (
                    <>
                        <TopDesignersFilter
                            filter={filter}
                            generateListingUrl={generateListingUrl}
                            trackFilterEvent={trackFilterEvent}
                            numOfTopDesigners={numOfTopDesigners}
                            title={messages.productListing.topDesignersThisWeek()}
                            useIdentifier={useIdentifier}
                        />
                        <span className={bem('title')}>{messages.productListing.azDesigners()}</span>
                    </>
                )}
                <div className={bem('searchWrapper')}>
                    <Input
                        aria-label={filter.label}
                        className={bem('designerFilter')}
                        value={searchTerm}
                        onChange={(e) => this.handleInputUpdate(e, trackDesignersSearchEntry)}
                        placeholder={messages.productListing.searchDesigner()}
                        type="text"
                        id="designerFilter"
                        onMouseDown={(e) => numOfTopDesigners && this.handleMouseDown(e, trackDesignersSearchFocus)} // sticky search when Top Designers are active
                        autoComplete={numOfTopDesigners ? 'off' : ''} // autocomplete off for sticky search
                    />
                    {searchTerm && <button onClick={this.onInputCancelClick} className={bem('designerFilterCancel')} />}
                </div>
                <div className={bem('list')} style={listStyles}>
                    <FavouriteDesignerFilter
                        generateListingUrl={generateListingUrl}
                        filter={filter}
                        valuesToUse={valuesToUse}
                        trackFilterEvent={trackFilterEvent}
                        trackFavoritesEvent={trackFavoritesEvent}
                    />
                    {labeledFilters.length !== 0 ? (
                        labeledFilters
                    ) : (
                        <p className={bem('noResultsMessage')}>{messages.productListing.noResultsSearch({ searchTerm })}</p>
                    )}
                </div>
            </div>
        );
    }
}

export function getGroupedValues(values) {
    const groupedValues = {};
    values.forEach((value) => {
        let title = value.label.charAt(0).toUpperCase();

        // is the first char a number, if so use the title 0-9
        if (/^\d+$/.test(title)) {
            title = '0-9';
        }

        if (groupedValues[title]) {
            groupedValues[title].push(value);
        } else {
            groupedValues[title] = [value];
        }
    });

    return groupedValues;
}

export default DesignerFilterGroup;
