import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchRecommendationsProductList from './SearchRecommendationsProductList';
import { useRecommendations as monetateRecommendationsFunc } from 'app/containers/PLPContainer/third-party';

const SearchRecommendationsWrapper = (props) => {
    const { messages, isMenswearOnTON } = props;

    const [recommendations, setRecommendations] = useState([]);
    const monetateRecommendations = monetateRecommendationsFunc();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const pageType = isMenswearOnTON ? 'searchnoresultsmens' : 'searchnoresultswomens';
            window.monetateQ = window.monetateQ || [];
            window.monetateQ.push(['setPageType', pageType]);
            window.monetateQ.push(['trackData']);
        }
    }, []);

    useEffect(() => {
        setRecommendations(monetateRecommendations);
    }, [monetateRecommendations]);

    return recommendations?.length > 0 ? <SearchRecommendationsProductList recommendations={recommendations} messages={messages} /> : null;
};

SearchRecommendationsWrapper.propTypes = {
    messages: PropTypes.shape({
        wearItWith: PropTypes.shape({
            heading: PropTypes.func.isRequired,
        }).isRequired,
        youMayAlsoLike: PropTypes.shape({
            heading: PropTypes.func.isRequired,
        }).isRequired,
        moreByThisDesigner: PropTypes.shape({
            heading: PropTypes.func.isRequired,
        }).isRequired,
    }),
    monetateRecommendationsFunc: PropTypes.func.isRequired,
    variation: PropTypes.string.isRequired,
    isMenswearOnTON: PropTypes.bool.isRequired,
};

export default SearchRecommendationsWrapper;
