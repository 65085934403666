import React, { useMemo, useState } from 'react';
import { func, object, shape, string, bool } from 'prop-types';
import { withRouter } from 'react-router';
import { MultiRangeInput, Dials, Labels, Rail, Track } from '@ynap/multi-range-input';
import { getCurrency, getCurrentRange, addPriceQueryParamsToPath } from './utils';

export const DEFAULT_PRICE_MIN = 0;
export const PriceFilterGroup = ({ filter, history, trackFilterEvent }) => {
    const defaultPriceMax = useMemo(() => filter.defaultPriceMax, []);
    const [domain, setDomain] = useState([0, defaultPriceMax]);

    const currentRange = getCurrentRange(filter, history.location);
    const currency = getCurrency(filter);

    const formatValues = ([min, max]) => {
        const maxSvalueSuffix = max === filter.defaultPriceMax ? '+' : '';
        return [`${currency}${min}`, `${currency}${max}${maxSvalueSuffix}`];
    };
    const { step } = filter;

    return (
        <MultiRangeInput
            domain={domain}
            currentRange={currentRange}
            onChange={(range) => {
                addPriceQueryParamsToPath({ history, range, defaultPriceMax });
                trackFilterEvent(filter, `price filter limit change`, range);
            }}
            updateDomain={setDomain}
            step={step}
        >
            <Labels formatValues={formatValues} />
            <Track />
            <Rail />
            <Dials />
        </MultiRangeInput>
    );
};

PriceFilterGroup.propTypes = {
    className: string,
    filter: object.isRequired,
    generateListingUrl: func.isRequired,
    history: object.isRequired,
    messages: shape({
        productListing: shape({
            unselectAll: func.isRequired,
        }).isRequired,
    }).isRequired,
    trackFilterEvent: func.isRequired,
    filterBySeoPath: bool.isRequired,
};

export default withRouter(PriceFilterGroup);
