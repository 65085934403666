export const getConfigByLayoutVariant = (item, productReplacementConfig) => {
    const { layoutVariant = '', items = [] } = item;
    const { single = {}, double = {}, row = {}, article = {} } = productReplacementConfig || {};
    const showContent = items[0]?.teaserTitle || items[0]?.teaserTextPlain;
    if (layoutVariant.includes('single'))
        return {
            aspectRatio: showContent ? single.withTextAspectRatio : single.aspectRatio,
            layoutType: getColumnPosition(items),
            numOfPids: 1,
            showContent
        };

    if (layoutVariant.includes('double'))
        return {
            aspectRatio: showContent ? double.withTextAspectRatio : double.aspectRatio,
            imageModifier: showContent ? 'double-content' : 'double',
            modifier: ['double'],
            layoutType: 'double',
            numOfPids: 2,
            showContent
        };

    if (layoutVariant.includes('row'))
        return {
            aspectRatio: showContent ? row.withTextAspectRatio : row.aspectRatio,
            imageModifier: showContent ? 'row-content' : 'row',
            modifier: ['row'],
            layoutType: 'row',
            numOfPids: 0,
            showContent
        };

    if (layoutVariant.includes('article'))
        return {
            aspectRatio: showContent ? article.withTextAspectRatio : article.aspectRatio,
            modifier: ['article'],
            layoutType: getColumnPosition(items),
            numOfPids: 1,
            showContent
        };
};

const getColumnPosition = items => {
    const position = items[0]?.anchorpoint || items[0]?.ynapParameter;
    return position ? 'single-column-' + position : 'single';
};

const getPage = parent => {
    return parent.tags?.find(tag => tag.name === 'pidreplacement')?.children?.find(tag => tag.name === 'pagination')?.children?.[0].name;
};

export const showInPage = (children = {}, parent = {}, pageNumber, enableLoadMore) => {
    if (getPage(parent) !== 'all' && pageNumber && pageNumber != 1) return true;

    const item = children.props?.item;
    if (item?.layoutVariant?.includes('advertisement') && pageNumber && pageNumber % 2 != 1 && enableLoadMore) return true;
};
