import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import InputMask from 'react-input-mask';
import { withRouter } from 'react-router';
import { func, object, shape, string } from 'prop-types';
import { getCurrency, getCurrentRealRange, addPriceQueryParamsToPath } from './utils';
import BEMHelper from '@ynap/bem-helper';
import Field from '@ynap/field';

const bem = new BEMHelper('PriceCustomerDefined');

const PriceCustomerDefined = ({ filter, history, messages, trackFilterEvent }) => {
    const currency = getCurrency(filter);
    const currentRange = getCurrentRealRange(history.location);
    const [lower, setLower] = useState();
    const [upper, setUpper] = useState();
    const [errorMsg, setErrorMsg] = useState('');

    const minPrice = filter?.plpMinPrice;
    const maxPrice = filter?.plpMaxPrice;

    const onBluerPrice = () => {
        const fromValue = lower ? Number(lower.replace(currency, '')) : 0;
        const toValue = upper ? Number(upper.replace(currency, '')) : maxPrice;
        let range = [fromValue, toValue];
        if (fromValue > toValue || fromValue > maxPrice || toValue < minPrice) {
            setLower(`${currency}${minPrice}`);
            setUpper(`${currency}${maxPrice}`);
            range = [minPrice, maxPrice];
            setErrorMsg(messages.productListing.priceFilter.amountError());
        } else {
            setLower(`${currency}${fromValue}`);
            setUpper(`${currency}${toValue}`);
            setErrorMsg();
        }
        addPriceQueryParamsToPath({ history, range });
        trackFilterEvent(filter, `price filter limit change`, range);
    };

    useEffect(() => {
        if (currentRange?.length && currentRange[1]) {
            setLower(`${currency}${currentRange[0]}`);
            setUpper(`${currency}${currentRange[1]}`);
        } else {
            setLower(`${currency}${minPrice}`);
            setUpper(`${currency}${maxPrice}`);
        }
    }, [minPrice, maxPrice]);

    const mask = `${currency}999999999`;

    return (
        <div className={bem('wrapper')}>
            <div className={bem('fieldsContainer')}>
                <Field
                    mask={mask}
                    maskChar={null}
                    component={InputMask}
                    className={bem('field')}
                    input={{
                        value: lower,
                        onChange: (e) => setLower(e.target.value),
                        onBlur: onBluerPrice,
                    }}
                    name="lower"
                    label={messages.productListing.priceFilter.from()}
                    showErrorNotification={errorMsg}
                />
                <Field
                    mask={mask}
                    maskChar={null}
                    component={InputMask}
                    className={bem('field')}
                    input={{
                        value: upper,
                        onChange: (e) => setUpper(e.target.value),
                        onBlur: onBluerPrice,
                    }}
                    name="upper"
                    id="upper"
                    label={messages.productListing.priceFilter.to()}
                    showErrorNotification={errorMsg}
                />
            </div>
            {errorMsg && <div className={bem('error')}>{errorMsg}</div>}
        </div>
    );
};

PriceCustomerDefined.propTypes = {
    currency: string.isRequired,
    filter: object.isRequired,
    currentRange: object,
    history: object.isRequired,
    messages: shape({
        productListing: shape({
            priceFilter: shape({
                from: func.isRequired,
                to: func.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    trackFilterEvent: func.isRequired,
};

export default withRouter(reduxForm({ form: 'price' })(PriceCustomerDefined));
