import React from 'react';
import { PropTypes } from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import ReactMarkdown from 'markdown-to-jsx';
import { ValueTransition } from '@ynap/value-transition';
import { trackProductReplacementContent } from '../analytics';
import { linkRenderer } from '@ynap/router';
import { getTags } from '@ycos/coremedia-renderer';
import { getConfigByLayoutVariant } from '../helper/helper';
import { Picture } from '@ycos/picture';
import { ratioToPadding } from '@ynap/fela';
import Topic from './topic';
import queryString from 'query-string';

const bem = new BEMHelper('ProductReplacementContent');

const ProductReplacementContent = ({
    item,
    parent,
    onTargetClick,
    productReplacementConfig,
    urlConfig,
    internal_urls,
    locale,
    imageQualityPercentage = 80,
}) => {
    if (!item) return null;
    const { teaserTitle, teaserText, subTitlePlain, href, ynapParameter, anchorpoint = '', picturesAndMedia: pictures = [] } = item;
    const { aspectRatio, modifier, imageModifier, showContent } = getConfigByLayoutVariant(parent, productReplacementConfig);

    // Calculate aspect ratio as a decimal fraction for Picture component to * 100 for padding-top hack
    const forceAspectRatioDesktop = ratioToPadding(aspectRatio);
    const forceAspectRatioMobile = ratioToPadding(productReplacementConfig?.mobileImage);
    const topic = getTags(item.tags, 'Journal.Topics');

    const content = (
        <div className={bem(null, modifier)}>
            {pictures.length ? (
                <div className={bem('imageWrapper', imageModifier)}>
                    <Picture
                        images={pictures}
                        forceAspectRatioDesktop={forceAspectRatioDesktop}
                        forceAspectRatioMobile={forceAspectRatioMobile}
                        imagesphere={{ quality: imageQualityPercentage }}
                        style={{ objectFit: 'cover' }} // passed to <img>
                    />
                </div>
            ) : null}
            {showContent && (
                <div className={bem(ynapParameter ? 'content-single' : 'content', [modifier ? 'gridStyle' : ''])}>
                    {topic?.length !== 0 && (
                        <Topic className={bem('topic')} style={{ height: '30px', alignSelf: 'start' }}>
                            {topic}
                        </Topic>
                    )}
                    {teaserTitle && (
                        <ValueTransition component="h2" className={bem('title')}>
                            {teaserTitle}
                        </ValueTransition>
                    )}
                    {(teaserText || href) && (
                        <div className={bem(ynapParameter ? 'text-single' : 'text')}>
                            {teaserText && (
                                <ReactMarkdown className={bem('copy')} options={{ forceWrapper: true }}>
                                    {teaserText}
                                </ReactMarkdown>
                            )}
                            {href && <span className={bem('link')}>{subTitlePlain}</span>}
                        </div>
                    )}
                </div>
            )}
        </div>
    );

    const newHref = typeof href === 'string' ? queryString.exclude(href, ['cm_sp']) : href;

    const trackingValue = trackProductReplacementContent(item, parent.layoutVariant, parent.position);
    const withLink = ({ children, onClick }) => linkRenderer(urlConfig, internal_urls, locale, `${newHref}${anchorpoint}`, children, onClick);

    return <>{newHref ? withLink({ children: content, onClick: () => onTargetClick(trackingValue) }) : content}</>;
};

ProductReplacementContent.layoutVariant = ['yos-plp-product-replacement-content'];

ProductReplacementContent.propTypes = {
    item: PropTypes.object.isRequired,
    parent: PropTypes.shape({
        layoutVariant: PropTypes.string,
        position: PropTypes.string,
    }).isRequired,
    onTargetClick: PropTypes.func.isRequired,
    productReplacementConfig: PropTypes.object,
    urlConfig: PropTypes.object.isRequired,
    internal_urls: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    imageQualityPercentage: PropTypes.number,
};

export default ProductReplacementContent;
