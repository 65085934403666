import React, { useState, useEffect } from 'react';
import { func, object } from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { Link } from '@ynap/router';
import { useConfig, useMessages } from '@ynap/immutables-provider';

const bem = new BEMHelper('FavouriteDesignerFilter');

const FavouriteDesignerFilter = ({ generateListingUrl, filter, valuesToUse, trackFavoritesEvent }) => {
    const { head } = useConfig();
    if (!head?.daz?.favouriteDesignerEnabled) return null;

    const [userSummary, setUserSummary] = useState([]);

    const filterPreferences = (preferences, availableDesigners) => {
        return availableDesigners.filter((designer) => preferences.find((preference) => preference.name?.toLowerCase() === designer.identifier?.toLowerCase()));
    };

    useEffect(() => {
        const getUserSummary = async () => {
            const userSummary = await window.SF?.getUserSummary();
            setUserSummary(userSummary);
        };

        getUserSummary();
    }, []);

    const {
        productListing: { favDesignerFilterName },
    } = useMessages();

    if (!userSummary) {
        return null;
    }

    const filteredFavouriteDesigners = userSummary.designerPreferences && filterPreferences(userSummary.designerPreferences, valuesToUse);
    const hiddenButton = !filteredFavouriteDesigners || filteredFavouriteDesigners?.length == 0;
    const favouriteDesignersFacet = filteredFavouriteDesigners?.map((designer) => designer.value);

    const handleClick = () => trackFavoritesEvent();

    return (
        <Link
            className={bem('favouriteDesigner', [hiddenButton && 'hidden'])}
            onClick={() => handleClick()}
            to={generateListingUrl({
                toggleParams: { [filter.queryKey]: favouriteDesignersFacet },
                clearParamsByKey: ['pageNumber'],
                preserveAndAddToParamsByKey: [filter.queryKey],
            })}
        >
            <span className={bem('favouriteDesignerIcon')} />
            <span className={bem('favouriteDesignerTitle')}>{favDesignerFilterName()}</span>
        </Link>
    );
};

FavouriteDesignerFilter.propTypes = {
    generateListingUrl: func.isRequired,
    filter: object.isRequired,
    valuesToUse: object.isRequired,
    trackFavoritesEvent: func.isRequired,
};

export default FavouriteDesignerFilter;
