import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { redirectToLogin } from '@ynap/redirect-utils';
import { ValidatedField } from '@ynap/field';
import track from 'react-tracking';
import { SimpleAsyncButton } from '@ynap/button';
import { trackEmailSignup } from './analytics';

const bem = new BEMHelper('RegistrationForm');

@track({
    category: {
        primaryCategory: 'email subscription',
    },
})
class RegistrationForm extends PureComponent {
    state = {
        email: '',
        errorMessage: '',
    };

    validateForm = (inputValue) => {
        const { messages } = this.props;
        let errorMessage = '';

        switch (true) {
            case !inputValue || !inputValue.trim():
                errorMessage = messages.errors.empty();
                break;
            case !inputValue.includes('@') || !inputValue.includes('.'):
                errorMessage = messages.errors.invalid({ field: 'email' });
                break;
            default:
                errorMessage = '';
        }

        return errorMessage;
    };

    @track(() => trackEmailSignup())
    handleOnClick = (event) => {
        const { locale } = this.props;
        const { email } = this.state;

        event && event.preventDefault && event.preventDefault();

        const errorMessage = this.validateForm(email);

        if (errorMessage) {
            this.setState({ errorMessage });
            return;
        }

        redirectToLogin(locale, { email, redirect: `/${locale}/account/` });
    };

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value,
            errorMessage: '',
        });
    };

    render() {
        const { messages, className } = this.props;
        const { submit, emailLabel } = messages.accountUtility;

        return (
            <form className={bem(null, null, className)}>
                <ValidatedField error={this.state.errorMessage} className={bem('email-field')}>
                    <input
                        onChange={this.handleEmailChange}
                        className={bem('email-text', { error: this.state.errorMessage })}
                        placeholder="your@address.com"
                        value={this.state.email}
                        aria-label={emailLabel()}
                    />
                </ValidatedField>
                <SimpleAsyncButton className={bem('submit-button')} copy={submit()} onClick={this.handleOnClick} data-target="email-registration-submit" />
            </form>
        );
    }
}

RegistrationForm.propTypes = {
    className: PropTypes.string,
    messages: PropTypes.shape({
        accountUtility: PropTypes.shape({
            signUp: PropTypes.func.isRequired,
        }),
        errors: PropTypes.shape({
            invalid: PropTypes.func.isRequired,
            empty: PropTypes.func.isRequired,
        }),
    }),
};

RegistrationForm.defaultProps = {
    className: '',
};

export default RegistrationForm;
