import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { ValueTransition } from '@ynap/value-transition';

const bem = new BEMHelper('SelectedFilterLabel');

class SelectedFilterLabel extends PureComponent {
    getActiveFiltersLabel(appliedLabels = [], messages) {
        let messageString = messages.productListing.allSelected();

        if (appliedLabels && appliedLabels.length) {
            if (appliedLabels.length === 1) {
                messageString = appliedLabels[0];
            }
            if (appliedLabels.length > 1) {
                messageString = messages.productListing.numberSelected({
                    number: appliedLabels.length,
                });
            }
        }
        return messageString;
    }

    render() {
        const { filterName, appliedLabels, messages, newValueAnimation, id } = this.props;
        const newValueAnimationModifier = newValueAnimation ? 'new' : null;
        const activeFiltersLabel = this.getActiveFiltersLabel(appliedLabels, messages);
        return (
            <Fragment>
                <div className={bem('title')}>{filterName}</div>
                <ValueTransition
                    className={bem('subTitle', [newValueAnimationModifier, id])}
                    component="div"
                    classAnimationModifier={bem('subTitle', 'old')}
                    animationName="fadeOut"
                >
                    {activeFiltersLabel}
                </ValueTransition>
            </Fragment>
        );
    }
}

const propTypes = {
    filterName: PropTypes.string.isRequired,
    appliedLabels: PropTypes.array,
    messages: PropTypes.shape({
        productListing: PropTypes.shape({
            allSelected: PropTypes.func.isRequired,
            numberSelected: PropTypes.func.isRequired,
        }).isRequired,
    }).isRequired,
};

SelectedFilterLabel.propTypes = propTypes;

export default SelectedFilterLabel;
