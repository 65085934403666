import React, { PureComponent } from 'react';
import { func, object, shape, string, bool } from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { Link } from '@ynap/router';
import { withRouter } from 'react-router';
import { CombinedSelect } from '@ynap/select';
import Filter from '../../Filter/Filter';

const bem = new BEMHelper('SchemaFilterGroup');

class SchemaFilterGroup extends PureComponent {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            multiSelectedCategory: [],
            disabledSML: '',
        };
    }
    static propTypes = {
        className: string,
        filter: object.isRequired,
        generateListingUrl: func.isRequired,
        messages: shape({
            productListing: shape({
                unselectAll: func.isRequired,
            }).isRequired,
        }).isRequired,
        updateSelectedSchema: func.isRequired,
        trackFilterEvent: func.isRequired,
        history: object.isRequired,
        withDoubleColumnLayout: bool,
    };

    static defaultProps = {
        withDoubleColumnLayout: true,
    };

    updateSchemaAndRedirect(id, value, location) {
        const { updateSelectedSchema, history } = this.props;
        updateSelectedSchema(id, value);

        const pathChange = history.location.pathname !== location.pathname;
        const searchChange = history.location.search !== location.search;

        if (pathChange || searchChange) {
            history.push(location);
        }
    }

    handleClick(category) {
        const removeItem = () => {
            this.setState({ multiSelectedCategory: this.state.multiSelectedCategory.filter((value) => value !== category) });
        };

        this.state.multiSelectedCategory.includes(category)
            ? removeItem()
            : this.setState({ multiSelectedCategory: this.state.multiSelectedCategory.concat([category]) });
    }

    componentDidMount() {
        const { filter, updateSelectedSchema } = this.props;
        const defaultSchema = filter.schemas.find((schema) => schema.selected) || filter.schemas[0] || { value: '' };
        updateSelectedSchema?.(filter.id, defaultSchema.value);
    }

    componentDidUpdate(prevProps) {
        const ukfilters = this.props.filter.schemas[0]?.values.filter((value) => value.isApplied === true);
        if (this.props.filter.appliedLabels?.length < prevProps.filter.appliedLabels?.length) {
            this.setState({ multiSelectedCategory: [] });
        }
        if (ukfilters?.length === 0 && this.props.filter?.appliedValues.length === 0) {
            this.setState({ disabledSML: 'disabled' });
        } else {
            this.setState({ disabledSML: null });
        }
    }

    render() {
        const { filter, messages, generateListingUrl, className, trackFilterEvent, withDoubleColumnLayout, useIdentifier } = this.props;
        const parentFilterValue = useIdentifier ? filter.id : filter.label;
        var options = filter.schemas;
        const defaultSchema = filter.schemas.find((schema) => schema.selected) || filter.schemas[0] || { value: '' };

        // Hide select in case there's only one value
        const displaySchemaSelect = filter.schemas.length > 1;

        const schemaSelect = (
            <CombinedSelect
                className={bem('selectSchema')}
                value={defaultSchema.value}
                onChange={(value) => {
                    this.updateSchemaAndRedirect(
                        filter.id,
                        value,
                        generateListingUrl({ toggleParams: { [filter.queryKey]: filter.appliedValues }, clearParamsByKey: ['pageNumber'] }),
                    );
                    trackFilterEvent({ identifier: value, label: options.find((option) => option.id === value).label }, 'country schema');
                }}
                options={options}
                numOfListElementsDisplayed={options.length}
            />
        );

        const selectedSchema = filter.schemas.find((schema) => schema.selected === true) || filter.schemas[0];

        const filters = selectedSchema.values.map((filterValue) => (
            <li key={filterValue.value}>
                <Filter
                    filter={filterValue}
                    link={generateListingUrl({
                        toggleParams: { [filter.queryKey]: filterValue.value },
                        clearParamsByKey: ['pageNumber'],
                    })}
                    className={'checkBox'}
                    trackFilterEvent={trackFilterEvent}
                    parentLabel={parentFilterValue}
                    useIdentifier={useIdentifier}
                    onClick={this.handleClick}
                    multiSelectedCategory={this.state.multiSelectedCategory}
                />
            </li>
        ));
        return (
            <div className={bem()}>
                <div className={bem('linksWrapper')}>
                    <Link
                        className={bem('unselectAll', this.state.disabledSML, className)}
                        to={generateListingUrl({ toggleParams: { [filter.queryKey]: filter.appliedValues } })}
                        onClick={() => trackFilterEvent({ label: 'unselect all', identifier: filter.id }, filter.label)}
                    >
                        {messages.productListing.unselectAll()}
                    </Link>
                </div>
                {displaySchemaSelect ? schemaSelect : <div className={bem('localSizeSchema')}>{defaultSchema.label}</div>}
                <ul className={bem('filtersWrapper', withDoubleColumnLayout ? 'doubleColumn' : null)}>{filters}</ul>
            </div>
        );
    }
}

export default withRouter(SchemaFilterGroup);
