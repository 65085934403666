import React from 'react';
import { PropTypes } from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import Advertisement from '@ynap/gpt-advertisement';
import { useAppState } from '@ynap/immutables-provider';

const bem = new BEMHelper('ProductReplacementAd');

const ProductReplacementAd = ({ item }) => {
    if (!item) return null;
    const appState = useAppState();
    const language = appState?.language;
    return (
        <div className={bem()}>
            <Advertisement
                slotSize={'fluid'}
                sizeMapping={[]}
                withViewportObserver={false}
                ynapadslot={{
                    adParameter: item.ynapParameter,
                    isProductReplacement: true
                }}
                targeting={{ language }}
            />
        </div>
    );
};

ProductReplacementAd.layoutVariant = ['yos-plp-product-replacement-advertisement'];

ProductReplacementAd.propTypes = {
    item: PropTypes.object.isRequired
};

export default ProductReplacementAd;
