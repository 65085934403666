import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import { ValueTransition } from '@ynap/value-transition';

const NoProductsBlockContainer = ({ item, children }) => {
    const bem = new BEMHelper('NoProductsBlockContainer');
    const { teaserTitle } = item;

    return (
        <div className={bem()}>
            <div className={bem('title')}>
                <ValueTransition component="h2">{teaserTitle}</ValueTransition>
            </div>
            {children}
        </div>
    );
};

NoProductsBlockContainer.layoutVariant = ['yos-plp-no-products'];

export default NoProductsBlockContainer;
