import React, { useEffect, useState } from 'react';
import BEMHelper from '@ynap/bem-helper';
import { parse } from 'query-string';
import PropTypes from 'prop-types';
import { Link } from '@ynap/router';
import Skeleton from '@ynap/skeleton';
import generateCategoryUrlObject from '../FilterGroups/MultiSelectFilterGroup/generateCategoryUrlObject';
import { useSelector, connect } from 'react-redux';
import { plpActions } from '../../../../redux/product-listing-state';
const { removeMultiFilter, removeSingleFilter } = plpActions;

const bem = new BEMHelper('FilterTags');

export const mapDispatchToProps = (dispatch) => ({
    unSelectFilter: (filter) => dispatch(removeMultiFilter(filter)),
    unSelectSingleFilter: (filter) => dispatch(removeSingleFilter(filter)),
});

const FilterTags = (props) => {
    const filterTags = useSelector((state) => state.plp?.content.singleFilters ?? []);
    const appliedTags = getAppliedFilterValues(props.filters);
    const multiFilterTags = useSelector((state) => state.plp?.content.multiFilters ?? []);
    const unSelectSingleFilter = props.unSelectSingleFilter;
    const unSelectFilter = props.unSelectFilter;
    const [urlTag, setUrlTag] = useState(false);
    const arrayTags = [...filterTags, ...multiFilterTags];
    const facetTags = getFacetTags(props, arrayTags, unSelectFilter, unSelectSingleFilter, filterTags, appliedTags, urlTag);
    const categoryTags = getCategoryTags(props, arrayTags, unSelectFilter, unSelectSingleFilter, filterTags, appliedTags, urlTag);
    const priceTag = getPriceTag(props, unSelectFilter, unSelectSingleFilter, filterTags, appliedTags, urlTag);
    const tags = props.loading ? <Skeleton className={bem('skeleton')} /> : [...facetTags, ...categoryTags, ...priceTag];
    // This will only render one filter tag skeleton, could add the below this to get correct number of filter tags to render skeleton for
    // props.location.search.split('facet').length-1;

    useEffect(() => {
        if (appliedTags.length >= 0 && arrayTags.length === 0 && filterTags.length === 0) {
            setUrlTag(true);
        }
        if (filterTags.length > 0) {
            setUrlTag(false);
        }
    }, [filterTags]);

    return (
        tags.length > 0 && (
            <div className={bem(null, null, props.className)}>
                <div className={bem('container')}>{tags}</div>
            </div>
        )
    );
};

FilterTags.propTypes = {
    loading: PropTypes.boolean,
    className: PropTypes.string,
};

const renderTag = (filterlink, id, appliedFilter, trackFilterEvent, unSelectFilter, unSelectSingleFilter, filterTags, favTags, urlTag) => {
    const getTagDescription = () => {
        if (appliedFilter?.appliedLabels && appliedFilter?.appliedLabels?.length) return appliedFilter?.appliedLabels[0];
        return (urlTag && favTags?.filter.label) || appliedFilter?.label;
    };

    return (
        <Link
            className={bem('link')}
            to={filterlink}
            key={id}
            onClick={() =>
                ((filterTags[0]?.label === appliedFilter?.label && unSelectSingleFilter(appliedFilter)) || unSelectFilter(appliedFilter)) &&
                trackFilterEvent(appliedFilter, `filter tag remove - ${favTags.filterSection}`, null, 'filter label')
            }
        >
            <div key={id} className={bem('tag')}>
                <div className={bem('tagDescription')}>{getTagDescription()}</div>
                <div className={bem('icon')} />
            </div>
        </Link>
    );
};

const getPriceTag = ({ filters, generateListingUrl, trackFilterEvent }, unSelectFilter, unSelectSingleFilter, filterTags, appliedTags, urlTag) => {
    const priceFilter = filters.find((f) => f.label === 'Price');

    if (!priceFilter?.variant?.filteredBy) return [];
    const favTags = appliedTags?.find((tag) => tag.filter.value === '-');

    const filterlink = {
        clearParamsByKey: ['pageNumber', 'maxPrice', 'minPrice'],
    };

    return [
        renderTag(
            generateListingUrl(filterlink),
            'price',
            priceFilter.variant,
            trackFilterEvent,
            unSelectFilter,
            unSelectSingleFilter,
            filterTags,
            favTags,
            urlTag,
        ),
    ];
};

//Applied facets are taken from the URL so the appear in the reverse order of when they were applied
const getFacetTags = ({ location, generateListingUrl, trackFilterEvent }, tags, unSelectFilter, unSelectSingleFilter, filterTags, appliedTags, urlTag) => {
    const queryStringObj = parse(location.search);
    const filtersFromUrlArr = Array.isArray(queryStringObj['facet']) ? queryStringObj['facet'] : [queryStringObj['facet']];
    return filtersFromUrlArr.reverse().reduce((acc, id) => {
        const filterlink = {
            toggleParams: { ['facet']: id },
            clearParamsByKey: ['pageNumber'],
        };
        const appliedFilter = tags?.find((tag) => tag.value === id);
        const favTags = appliedTags?.find((tag) => tag.filter.value === id);
        if (appliedFilter || favTags) {
            return [
                ...acc,
                renderTag(
                    generateListingUrl(filterlink),
                    id,
                    appliedFilter,
                    trackFilterEvent,
                    unSelectFilter,
                    unSelectSingleFilter,
                    filterTags,
                    favTags,
                    urlTag,
                ),
            ];
        } else {
            return acc;
        }
    }, []);
};

//Applied categories are not taken from the URL because they can switch between query params and pathname
export const getCategoryTags = (
    { filters, generateListingUrl, trackFilterEvent, filterCategoryByPath },
    tags,
    unSelectFilter,
    unSelectSingleFilter,
    filterTags,
    appliedTags,
    urlTag,
) => {
    return filters.reduce((acc, filterGroup, index) => {
        if (filterGroup.queryKey === 'addCategoryId' || filterGroup.queryKey === 'categoryId') {
            !filterGroup.hideAllSelect &&
                filterGroup.values.map((filter) => {
                    const appliedCategory = tags?.find((tag) => tag.categoryId === filter.categoryId);
                    const favTags = appliedTags?.find((tag) => tag.filter.categoryId === filter.categoryId);
                    if (appliedCategory || (urlTag && favTags)) {
                        //for all except the first filter in search
                        let filterlink;
                        if (filterCategoryByPath || index > 0) {
                            filterlink = generateCategoryUrlObject(filter, filterGroup, filterCategoryByPath);
                        } else {
                            //first filter in search, remove query addCategoryId;
                            filterlink = {
                                clearParamsByKey: ['pageNumber', 'addCategoryId', 'categoryId'],
                                ...(filter.queryKey === 'addCategoryId' ? { preserveParamsByKey: ['categoryId'] } : { preserveParamsByKey: ['facet'] }),
                            };
                        }
                        acc = [
                            ...acc,
                            renderTag(
                                generateListingUrl(filterlink),
                                appliedCategory?.categoryId,
                                appliedCategory,
                                trackFilterEvent,
                                unSelectFilter,
                                unSelectSingleFilter,
                                filterTags,
                                favTags,
                                urlTag,
                            ),
                        ];
                    }
                });
        }
        return acc;
    }, []);
};

export const getAppliedFilterValues = (filters) => {
    return (filters || []).reduce((filterValues, currentFilter) => {
        if (currentFilter.values && currentFilter.values.length) {
            if (currentFilter.label === 'Price') {
                if (currentFilter?.variant?.filteredBy) {
                    return filterValues.concat([
                        { filter: { label: currentFilter?.variant.appliedLabels, value: '-' }, filterSection: currentFilter.variant.label },
                    ]);
                }
                return filterValues.concat(getAppliedValuesFromFilterValues(currentFilter.values, currentFilter.label));
            }
            return filterValues.concat(getAppliedValuesFromFilterValues(currentFilter.values, currentFilter.label));
        }
        return filterValues;
    }, []);
};

export const getAppliedValuesFromFilterValues = (values, filterSection) => {
    return values.reduce((appliedValues, value) => {
        if (value.isApplied) {
            return appliedValues.concat({ filter: value, filterSection: filterSection });
        }
        return appliedValues;
    }, []);
};

export default connect(null, mapDispatchToProps)(FilterTags);
