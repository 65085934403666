import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BEMHelper from '@ynap/bem-helper';
import { useUserPreferences } from '@ynap/user-preferences-hook';
import track from 'react-tracking';
import { trackChangeFavouriteDesigner } from '../../analytics';
import SignIn from '../SignIn/SignIn';
import queryString from 'query-string';

const classNames = (object) =>
    Object.entries(object)
        .reduce((acc, [key, val]) => (val ? [...acc, key] : acc), [])
        .join(' ') || null;

const bem = new BEMHelper('FavouriteDesigner');
const prefId = 'DESIGNERS';

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

export const FavouriteDesigner = ({ designer, text, uberToken, locale, tracking, analytics, messages }) => {
    const [signInPrompt, setSignInPrompt] = useState(false);
    const {
        preferences,
        addUserPreference,
        removeUserPreference,
        loadingFavourite: loading,
        errors,
        authenticated,
        preferencesLoading,
    } = useUserPreferences(uberToken, prefId);
    const active = !!(preferences || [{ name: '' }]).find(({ name }) => name === designer);
    const previousErrors = usePrevious(errors);
    const previousPreferencesLoading = usePrevious(preferencesLoading);

    const handleClick = (onMount) => {
        if (!uberToken || !authenticated) {
            setSignInPrompt(true);
        } else {
            tracking.trackEvent(trackChangeFavouriteDesigner(analytics, [designer, active]));
            //Do not remove if handleClick called on mount (i.e. addDesignerId query)
            active ? !onMount && removeUserPreference([designer]) : addUserPreference({ values: [{ name: designer }] });
        }
    };

    useEffect(() => {
        if (
            (!previousErrors?.addUserPreference && errors.addUserPreference && !authenticated) ||
            (!previousErrors?.removeUserPreference && errors.removeUserPreference && !authenticated)
        ) {
            setSignInPrompt(true);
        }

        const queryParams = queryString.parse(window.location.search);
        if (previousPreferencesLoading && !preferencesLoading && !errors.getUserPreferences && queryParams?.addDesignerId === designer) {
            handleClick(true);
        }
    }, [errors, preferencesLoading]);

    return (
        <>
            <div className={bem('container')}>
                <button className={classNames({ active, loading })} onClick={() => handleClick(false)} />
                <span onClick={() => handleClick(false)}>{text}</span>
            </div>
            {signInPrompt && (
                <SignIn
                    messages={messages}
                    onClose={() => {
                        setSignInPrompt(false);
                    }}
                    locale={locale}
                    redirect={`${window.location.pathname}?addDesignerId=${designer}`}
                    designer={designer}
                />
            )}
        </>
    );
};

FavouriteDesigner.propTypes = {
    designer: PropTypes.string,
    text: PropTypes.string,
    uberToken: PropTypes.string,
    locale: PropTypes.string,
};

export default connect((state) => ({
    uberToken: state?.auth?.uberToken,
    locale: state?.appState?.locale,
}))(track()(FavouriteDesigner));
