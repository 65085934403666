import React, { useEffect, useState } from 'react';
import BEMHelper from '@ynap/bem-helper';
import PropTypes from 'prop-types';
import Overlay from '@ynap/overlay';
import { Button } from '@ynap/button';
import { getItemFromWebStorage, setItemOnWebStorage } from '@ynap/web-storage-utils';
import track from 'react-tracking';
import { withAnalytics } from '@ynap/analytics';
import { trackFavouriteDesignerOnboarding } from '../../analytics';

const bem = new BEMHelper('Onboarding');

const Onboarding = ({ messages, localStorageKey, tracking, analytics }) => {
    const [isViewed, setIsViewed] = useState(true);

    useEffect(() => {
        if (localStorageKey) {
            const isItemStored = getItemFromWebStorage('localStorage', localStorageKey);
            setIsViewed(isItemStored);
            !isItemStored && tracking?.trackEvent(trackFavouriteDesignerOnboarding(analytics, ['ONBOARDING_DISPLAY']));
        }
    }, []);

    const onClose = (action) => {
        setIsViewed(true);
        setItemOnWebStorage('localStorage', localStorageKey, true);

        if (action === 'CLOSE') {
            tracking?.trackEvent(trackFavouriteDesignerOnboarding(analytics, ['ONBOARDING_CLOSE']));
        }
        if (action === 'CONFIRM') {
            tracking?.trackEvent(trackFavouriteDesignerOnboarding(analytics, ['ONBOARDING_CONFIRM']));
        }
    };

    return (
        !isViewed && (
            <div className={bem('null, null')}>
                <Overlay
                    onClose={() => onClose('CLOSE')}
                    handleBodyClick={() => onClose('CLOSE')}
                    className={{ container: bem('overlay'), content: bem('content'), button: bem('close') }}
                >
                    <h2 className={bem('title')}>{messages.favouriteDesigners?.title()}</h2>
                    <p className={bem('text')}>{messages.favouriteDesigners?.text()}</p>
                    <Button type="secondary" onClick={() => onClose('CONFIRM')} className={bem('button')}>
                        {messages.favouriteDesigners?.cta()}
                    </Button>
                </Overlay>
            </div>
        )
    );
};

Onboarding.propTypes = {
    messages: PropTypes.func,
    localStorageKey: PropTypes.string,
};

export default withAnalytics(track()(Onboarding));
