import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ProductList from './ProductList';
import BEMHelper from '@ynap/bem-helper';
import { Link } from '@ynap/router';
import { ProductGrid } from '@ynap/product-listing-page';
import { SimpleAsyncButton } from '@ynap/button';
import { ScrollToTop } from '@ynap/scroll-to-top';
import { parseUrl } from 'query-string';
import { performance } from '@ynap/clientside-monitoring';
import { withOptimizely } from '@ynap/optimizely-utils';
import { useWishList } from '@ynap/api-hooks';
import { useSelector } from 'react-redux';

export const ProductListWithLoadMore = (props) => {
    const bem = new BEMHelper('ProductListWithLoadMore');
    const {
        ProductSummary,
        preloadedProductItems,
        productLists,
        productImageAspectRatio,
        imageSrcsetSize,
        messages,
        disableSPA,
        locale,
        baseUrl,
        paginationData,
        metaData,
        enableLoadMore,
        renderCoreMediaContent,
        mainContent,
        setListingHeight,
        trackProductReplacementClickEvent,
        productReplacementConfig,
        ssr,
        urlConfig,
        internal_urls,
        imageQualityPercentage,
        wishlist,
        country,
    } = props;

    return productLists.map((pl, index) => {
        const parsedUrlObject = parseUrl(pl.key);
        const pageNumber = parsedUrlObject.query.pageNumber;

        const rules = (component) => ({
            ...component,
            numOfProducts: pl.products.length,
            onTargetClick: trackProductReplacementClickEvent,
            locale,
            internal_urls,
            urlConfig,
            productReplacementConfig,
            pageNumber,
            enableLoadMore,
            imageQualityPercentage,
        });
        const loadingClass = pl.loading ? 'loading' : null;
        return (
            <ProductGrid
                setListingHeight={setListingHeight}
                index={index}
                className={bem('listingGrid', loadingClass)}
                key={pl.key}
                url={metaData.canonicalUrl}
                numberOfItems={paginationData.recordSetTotal}
                wishlist={wishlist}
            >
                <ProductList
                    {...props}
                    ProductSummary={ProductSummary}
                    products={pl.products}
                    className={bem('productItem')}
                    preloadedProductItems={preloadedProductItems}
                    productImageAspectRatio={productImageAspectRatio}
                    productListKey={pl.key}
                    messages={messages}
                    pageNumber={pageNumber}
                    loading={pl.loading}
                    imageSrcsetSize={imageSrcsetSize}
                    disableSPA={disableSPA}
                    locale={locale}
                    baseUrl={baseUrl}
                    enableLoadMore={enableLoadMore}
                    ssr={ssr}
                    wishlist={wishlist}
                    listingPage
                    country={country}
                />
                {!pl.loading && renderCoreMediaContent(mainContent, rules)}
            </ProductGrid>
        );
    });
};

const ProductListLayout = (props) => {
    const bem = new BEMHelper('ProductListLayout');
    const {
        productLists = [],
        messages,
        loading,
        preloadedProductItems,
        productImageAspectRatio,
        disableSPA,
        paginationData,
        locale,
        metaData,
        baseUrl,
        fetchMoreListing,
        enableLoadMore,
        generateListingUrl,
        ProductSummary,
        imageSrcsetSize,
        firstFetchedPageNumber,
        lastFetchedPageNumber,
        footerAd,
        renderCoreMediaContent,
        mainContent,
        trackProductReplacementClickEvent,
        trackLoadMoreEvent,
        setListingHeight,
        currentPage,
        trackBackToTopEvent,
        forceLogin,
        productReplacementConfig,
        ssr,
        urlConfig,
        internal_urls,
        customPageSize,
        optimizely,
        country,
        omnibusConfig,
    } = props;
    const totalNumberOfViewedProducts = lastFetchedPageNumber * paginationData.pageSize;
    const copy = messages.productListing.youHaveViewed({ totalNumberOfViewedProducts, totalProducts: paginationData.recordSetTotal });
    const percentage = ((lastFetchedPageNumber * paginationData.pageSize) / paginationData.recordSetTotal) * 100;
    const uberToken = useSelector((state) => state?.auth?.uberToken);
    const wishlist = useWishList(uberToken);
    const loadingColors = useSelector((state) => state?.plp?.listing?.siblingsProducts?.loading);

    const moreButtonProps = {
        type: 'secondary',
        loading,
        copy: messages.productListing.loadMore(),
        onClick: () => loadMore(false, customPageSize),
    };

    const previousButtonProps = {
        type: 'secondary',
        loading,
        copy: messages.productListing.loadPrevious(),
        onClick: () => loadMore(true, customPageSize),
    };

    const totalPages = paginationData && paginationData.totalPages;
    const previousPage = firstFetchedPageNumber > 1 ? firstFetchedPageNumber - 1 : 1;
    const nextPage = lastFetchedPageNumber < totalPages ? lastFetchedPageNumber + 1 : totalPages;
    const previousPageParams = previousPage > 1 ? { replaceParams: { pageNumber: previousPage } } : { clearParamsByKey: ['pageNumber'] };
    const previousPageLink = generateListingUrl(previousPageParams);
    const nextPageLink = generateListingUrl({ replaceParams: { pageNumber: nextPage, pageSize: customPageSize } });
    const prevent = (e) => e.preventDefault();

    const loadMore = (previous = false, customPageSize) => {
        const value = previous ? 'load less' : 'load more';
        performance.intTiming.markSpaInteractionTime(value);
        trackLoadMoreEvent(value, currentPage);

        if (optimizely) {
            optimizely.track('page_event');
        }
        return fetchMoreListing(previous, customPageSize);
    };

    return (
        <Fragment>
            {enableLoadMore && firstFetchedPageNumber > 1 && (
                <Link className={bem('loadPrevious')} to={previousPageLink} onClick={prevent}>
                    <SimpleAsyncButton containerClass={bem('loadMoreCTA')} className={bem('loadMoreButton')} {...previousButtonProps} />
                </Link>
            )}

            <ProductListWithLoadMore
                wishlist={wishlist}
                paginationData={paginationData}
                metaData={metaData}
                ProductSummary={ProductSummary}
                productLists={productLists}
                className={bem('productItem')}
                preloadedProductItems={preloadedProductItems}
                productImageAspectRatio={productImageAspectRatio}
                messages={messages}
                imageSrcsetSize={imageSrcsetSize}
                disableSPA={disableSPA}
                locale={locale}
                baseUrl={baseUrl}
                fetchMore={fetchMoreListing}
                totalPages={totalPages}
                enableLoadMore={enableLoadMore}
                renderCoreMediaContent={renderCoreMediaContent}
                trackProductReplacementClickEvent={trackProductReplacementClickEvent}
                mainContent={mainContent}
                setListingHeight={setListingHeight}
                loading={loading}
                forceLogin={forceLogin}
                productReplacementConfig={productReplacementConfig}
                ssr={ssr}
                urlConfig={urlConfig}
                internal_urls={internal_urls}
                country={country}
                omnibusConfig={omnibusConfig}
                loadingColors={loadingColors}
            />

            {enableLoadMore && lastFetchedPageNumber < totalPages && (
                <div className={bem('loadMore')}>
                    <p className={bem('productsViewed')}>{copy}</p>
                    <div className={bem('progress')}>
                        <div className={bem('bar')} style={{ width: `${percentage}%` }} />
                    </div>

                    <Link to={nextPageLink} onClick={prevent}>
                        <SimpleAsyncButton containerClass={bem('loadMoreCTA')} className={bem('loadMoreButton')} {...moreButtonProps} />
                    </Link>
                </div>
            )}
            {enableLoadMore && paginationData.recordSetCount > 16 && <ScrollToTop trackBackToTopEvent={trackBackToTopEvent} className={bem('scrollTop')} />}
            {paginationData && paginationData.pageNumber === paginationData.totalPages && renderCoreMediaContent(footerAd)}
        </Fragment>
    );
};

ProductListLayout.displayName = 'ProductListLayout';

ProductListLayout.propTypes = {
    productLists: PropTypes.array.isRequired,
    messages: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    disableSPA: PropTypes.bool.isRequired,
    paginationData: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    metaData: PropTypes.object.isRequired,
    baseUrl: PropTypes.string.isRequired,
    fetchMoreListing: PropTypes.func.isRequired,
    enableLoadMore: PropTypes.bool.isRequired,
    generateListingUrl: PropTypes.func.isRequired,
    ProductSummary: PropTypes.func.elementType,
    imageSrcsetSize: PropTypes.string.isRequired,
    firstFetchedPageNumber: PropTypes.number.isRequired,
    lastFetchedPageNumber: PropTypes.number.isRequired,
    footerAd: PropTypes.object,
    renderCoreMediaContent: PropTypes.func.isRequired,
    productImageAspectRatio: PropTypes.string.isRequired,
    mainContent: PropTypes.string.isRequired,
    trackLoadMoreEvent: PropTypes.func,
    setListingHeight: PropTypes.func,
    currentPage: PropTypes.number,
    trackBackToTopEvent: PropTypes.func,
    forceLogin: PropTypes.bool,
    trackProductReplacementClickEvent: PropTypes.func,
    productReplacementConfig: PropTypes.object,
    urlConfig: PropTypes.object.isRequired,
    internal_urls: PropTypes.array.isRequired,
    ssr: PropTypes.bool.isRequired,
    customPageSize: PropTypes.customPageSize,
};

export default withOptimizely(ProductListLayout);
