const generateCategoryUrlObject = (currentFilter, filterGroup, filterBySeoPath = true) => {
    const { queryKey } = filterGroup;
    let locationObject = { toggleParams: { [queryKey]: currentFilter.categoryId } };
    const numberOfAppliedFilters = filterGroup.values.filter((f) => f.isApplied).length;
    if (!filterBySeoPath) {
        if (numberOfAppliedFilters === 0) {
            locationObject = { toggleParams: { ['addCategoryId']: currentFilter.categoryId } };
        } else if (numberOfAppliedFilters === 1) {
            locationObject = {
                toggleParams: { ['addCategoryId']: currentFilter.categoryId },
            };
        } else if (numberOfAppliedFilters === 2 && currentFilter.isApplied) {
            locationObject = {
                toggleParams: { ['addCategoryId']: currentFilter.categoryId },
            };
        }
        const clearParamsKeys = locationObject.clearParamsByKey ? locationObject.clearParamsByKey : [];
        return { ...locationObject, clearParamsByKey: ['pageNumber', ...clearParamsKeys], preserveParamsByKey: ['facet', 'categoryId'] };
    }

    if (numberOfAppliedFilters === 0) {
        //No categories Selected - new path
        locationObject = { pathname: currentFilter.seo?.seoURLKeyword, relativeToBasePath: true };
    } else if (numberOfAppliedFilters === 1) {
        //One category selected - if current filter remove path - if NOT current filter remove path and toggleParams for both
        const selectedFilterId = filterGroup.values.find((f) => f.isApplied).categoryId;
        locationObject = {
            pathname: filterGroup.value,
            relativeToBasePath: true,
            ...(!currentFilter.isApplied
                ? {
                      toggleParams: { [queryKey]: [currentFilter.categoryId, selectedFilterId] },
                  }
                : null),
        };
    } else if (numberOfAppliedFilters === 2 && currentFilter.isApplied) {
        //Two categories selected AND current filter is one of them - add new path for other applied filter
        //new path removes queary params unless preserved i.e. preserveParamsByKey
        const { value: otherCategoryURL } = filterGroup.values.find((f) => {
            return f.isApplied && f.categoryId !== currentFilter.categoryId;
        });
        locationObject = { pathname: otherCategoryURL, relativeToBasePath: true };
    }

    return { ...locationObject, clearParamsByKey: ['pageNumber'], preserveParamsByKey: ['facet'] };
};

export default generateCategoryUrlObject;
