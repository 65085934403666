import React, { PureComponent } from 'react';
import { func, object, shape, string, bool } from 'prop-types';
import ToggleSlide from '@ynap/toggle-slide';
import BEMHelper from '@ynap/bem-helper';
import { Link } from '@ynap/router';
import Filter from '../../Filter/Filter';
import generateCategoryUrlObject from './generateCategoryUrlObject';
import { connect } from 'react-redux';
import { plpActions } from '../../../../../redux/product-listing-state';
const { unselectAllFilters } = plpActions;

const bem = new BEMHelper('MultiSelectFilterGroup');

export const mapDispatchToProps = (dispatch) => ({
    unSelectAllFilter: (filter) => dispatch(unselectAllFilters(filter)),
});
class MultiSelectFilterGroup extends PureComponent {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            multiSelectedCategory: [],
        };
    }
    static propTypes = {
        className: string,
        filter: object.isRequired,
        generateListingUrl: func.isRequired,
        messages: shape({
            productListing: shape({
                unselectAll: func.isRequired,
            }).isRequired,
        }).isRequired,
        trackFilterEvent: func.isRequired,
        trackFilterToggleClick: func.isRequired,
        filterBySeoPath: bool.isRequired,
    };

    clearCategoryUrlObject(filterBySeoPath) {
        const { filter } = this.props;
        const { queryKey, appliedValues } = filter;
        let locationObject = {};

        if (filterBySeoPath) {
            locationObject = { toggleParams: { [queryKey]: appliedValues } }; //removes categoryIds
            if (appliedValues.length == 1) {
                locationObject = { pathname: filter.value, relativeToBasePath: true }; //removes path
            }
        } else {
            const selectedFilterId = filter.values.filter((f) => f.isApplied).map((i) => i.categoryId);
            locationObject = { toggleParams: { ['addCategoryId']: selectedFilterId } }; //toggle categoryIds
        }

        return { ...locationObject, clearParamsByKey: ['pageNumber', filterBySeoPath && 'addCategoryId'], preserveParamsByKey: ['facet'] };
    }

    handleClick(category) {
        const removeItem = () => {
            this.setState({ multiSelectedCategory: this.state.multiSelectedCategory.filter((value) => value !== category) });
        };

        this.state.multiSelectedCategory.includes(category)
            ? removeItem()
            : this.setState({ multiSelectedCategory: this.state.multiSelectedCategory.concat([category]) });
    }

    componentDidUpdate(prevProps) {
        if (this.props.filter.appliedLabels?.length < prevProps.filter.appliedLabels?.length) {
            this.setState({ multiSelectedCategory: [] });
        }
    }

    render() {
        const { filter, messages, generateListingUrl, className, trackFilterEvent, trackFilterToggleClick, filterBySeoPath, useIdentifier, unSelectAllFilter } =
            this.props;
        const { queryKey, appliedValues, values } = filter;
        const parentFilterValue = useIdentifier ? filter.id : filter.label;
        const isCategoryGroup = queryKey === 'addCategoryId';
        const filters = filter.values.map((filterValue) => {
            const { value } = filterValue;
            return (
                <Filter
                    filter={filterValue}
                    link={
                        isCategoryGroup
                            ? generateListingUrl(generateCategoryUrlObject(filterValue, filter, filterBySeoPath))
                            : generateListingUrl({ toggleParams: { [queryKey]: filterValue.value }, clearParamsByKey: ['pageNumber'] })
                    }
                    type={'checkbox'}
                    key={value}
                    className={'checkBox'}
                    trackFilterEvent={trackFilterEvent}
                    parentLabel={parentFilterValue}
                    onClick={this.handleClick}
                    multiSelectedCategory={this.state.multiSelectedCategory}
                />
            );
        });

        const disabled = appliedValues && appliedValues.length === 0 ? 'disabled' : null;
        const sliderClass = filter.isFilterAllApplied ? 'on' : null;
        return (
            <div className={bem(null, null, className)}>
                <Link
                    className={bem('unselectAll', disabled, null)}
                    onClick={() => {
                        trackFilterEvent({ label: 'unselect all', identifier: filter.id }, parentFilterValue);
                        if (filter.appliedLabels.length > 0) {
                            unSelectAllFilter(filter.appliedLabels);
                        }
                    }}
                    to={
                        isCategoryGroup
                            ? generateListingUrl(this.clearCategoryUrlObject(filterBySeoPath))
                            : generateListingUrl({ toggleParams: { [queryKey]: appliedValues }, clearParamsByKey: ['pageNumber'] })
                    }
                >
                    {messages.productListing.unselectAll()}
                </Link>
                <div className={bem(null, null, className)}>
                    {filter.id === 'CONSIDERED' && (
                        <Link
                            onClick={() => {
                                return !filter.isFilterAllApplied
                                    ? trackFilterToggleClick(
                                          { label: filter.values.map((filterValue) => filterValue.label).join(', '), identifier: filter.id },
                                          parentFilterValue,
                                          'enabled',
                                      )
                                    : trackFilterToggleClick({ label: '', identifier: filter.id }, parentFilterValue, 'unselect');
                            }}
                            to={
                                filter.isFilterAllApplied
                                    ? generateListingUrl({ toggleParams: { [queryKey]: appliedValues }, clearParamsByKey: ['pageNumber'] })
                                    : generateListingUrl({
                                          toggleParams: { [queryKey]: [...values.map((value) => value.value), ...appliedValues] },
                                          clearParamsByKey: ['pageNumber'],
                                      })
                            }
                        >
                            <div className={bem('filterToggleWrapper', null, null)}>
                                <p className={bem('filterToggleLabel', null, null)}>{messages.considered?.consideredFilters()}</p>
                                <ToggleSlide
                                    testId="filterToggle"
                                    className={{ sliderClass: sliderClass, toggleClass: 'filterToggle', textClass: 'filterToggle' }}
                                />
                            </div>
                        </Link>
                    )}
                    {filters}
                </div>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(MultiSelectFilterGroup);
