import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import Skeleton from '@ynap/skeleton';

const bem = new BEMHelper('Footer');

export const FooterContainer = (props) => {
    const { loading } = props;
    if (loading)
        return (
            <>
                <FooterSkeleton {...props} />
            </>
        );
    return (
        <>
            <FooterContent {...props} />
        </>
    );
};

export const FooterContent = ({ item, children }) => {
    if (!item || !children) return null;

    const { teaserTitle, teaserText } = item;

    return (
        <div className={bem()}>
            {teaserText && <div className={bem('text')}>{teaserText}</div>}
            {teaserTitle && <div className={bem('title')}>{teaserTitle}</div>}
            <div className={bem('container')}>{children}</div>
        </div>
    );
};

export const FooterSkeleton = ({ item = {} }) => {
    const { teaserTitle, teaserText } = item;
    const additionalStyle = {
        backgroundSize: '370px',
        height: '370px',
    };
    return (
        <div className={bem()}>
            {teaserText && <Skeleton className={bem('text')} />}
            {teaserTitle && <Skeleton className={bem('title')} />}
            <div className={bem('container')}>
                <Skeleton className={bem('item-skeleton')} additionalStyle={additionalStyle} />
                <Skeleton className={bem('item-skeleton')} additionalStyle={additionalStyle} />
                <Skeleton className={bem('item-skeleton')} additionalStyle={additionalStyle} />
            </div>
        </div>
    );
};

FooterContainer.layoutVariant = ['yos-plp-footer'];

FooterContainer.propTypes = {
    item: PropTypes.shape({
        teaserTitle: PropTypes.string,
        teaserText: PropTypes.string,
    }),
    children: PropTypes.object,
};

export default FooterContainer;
