import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { AccordionSection } from '@ynap/accordion';
import { FILTER_REMOVED, FILTER_ADDED } from '../Filters/FiltersWrapper/helpers';

const bem = new BEMHelper('PLPAccordionSection');

export default class PLPAccordionSection extends PureComponent {
    static propTypes = {
        active: PropTypes.bool,
        accordionAnimation: PropTypes.bool,
        status: PropTypes.string,
        isNotificationVisible: PropTypes.bool,
    };

    render() {
        const { active, accordionAnimation, status, isNotificationVisible = false, ...props } = this.props;
        let animationModifier = '';
        if (accordionAnimation) {
            switch (status) {
                case FILTER_REMOVED:
                    animationModifier = 'removed';
                    break;
                case FILTER_ADDED:
                    animationModifier = 'added';
                    break;
                default:
                    animationModifier = 'accordionAnimation';
            }
        }
        //override is a modifier to allow PLPAccordionSection css to be more specific than AccordionSection's css for the active modifier
        return (
            <AccordionSection
                {...props}
                isActive={active}
                isMobileFullScreen={true}
                className={bem(null, [animationModifier, active ? 'active' : '', active && isNotificationVisible ? 'withNotification' : undefined], 'override')}
                headingClassName={bem(null, null, ['headingOverride', 'activeHeadingOverride'])}
            />
        );
    }
}
