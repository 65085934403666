import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import { Picture } from '@ycos/picture';
import { ValueTransition } from '@ynap/value-transition';
import { linkRenderer } from '@ynap/router';

const NoProductsBlock = ({ item, imageQualityPercentage = 80, urlConfig, internal_urls, locale }) => {
    const bem = new BEMHelper('NoProductsBlock');
    const { teaserTitle, teaserText, subTitle, href, picturesAndMedia: pictures, anchorpoint = '' } = item;

    return (
        <div className={bem()}>
            <div className={bem('image')}>
                {linkRenderer(
                    urlConfig,
                    internal_urls,
                    locale,
                    `${href}${anchorpoint}`,
                    <Picture images={pictures} lazy={false} imagesphere={{ quality: imageQualityPercentage }} />,
                    null,
                    bem('link'),
                )}
            </div>
            {linkRenderer(
                urlConfig,
                internal_urls,
                locale,
                `${href}${anchorpoint}`,
                <>
                    <ValueTransition component="p" className={bem('title')}>
                        {teaserTitle}
                    </ValueTransition>
                    {teaserText && (
                        <ValueTransition component="p" className={bem('teaser-text')}>
                            {teaserText}
                        </ValueTransition>
                    )}
                    <ValueTransition component="p" className={bem('sub-title')}>
                        {subTitle}
                    </ValueTransition>
                </>,
                null,
                bem('link'),
            )}
        </div>
    );
};

NoProductsBlock.layoutVariant = ['yos-plp-no-products-block'];

export default NoProductsBlock;
