import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import FounditList from './FounditList/FounditList';

interface FounditWrapperProps {
    content: {
        title: string;
        href: string;
    }[];
    urlConfig: { [key: string]: object };
    locale: string;
    internal_urls: string[];
    messages: Record<string, unknown>;
}

const bem = new BEMHelper('FounditWrapper');

const FounditWrapper: React.FC<FounditWrapperProps> = ({ content, urlConfig, locale, internal_urls, messages }) => {
    return (
        <div className={bem()}>
            <FounditList
                items={content}
                urlConfig={urlConfig}
                locale={locale}
                internal_urls={internal_urls}
                messages={messages}
            />
        </div>
    );
};

export default React.memo(FounditWrapper);
