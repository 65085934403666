const FILTER_ADDED = 'ADDED';
const FILTER_REMOVED = 'REMOVED';
const CATEGORY_FILTER_ID = 'CATEGORIES';

// I think covers most cases without having to check all the filter entries
// Additional case added to check for filters.values[].identifier, for flat cat hierarchy
const filtersNotEqual = function (prevFilters, filters) {
    if (prevFilters.length !== filters.length) {
        return true;
    }

    const prevFiltersString = prevFilters
        .map((f) => [f.id, f.appliedValues, ...(f.values?.map((v) => [v.identifier, v.value]) || []), f.schemas && f.schemas.map((s) => s.selected)])
        .toString();
    const filtersString = filters
        .map((f) => [f.id, f.appliedValues, ...(f.values?.map((v) => [v.identifier, v.value]) || []), f.schemas && f.schemas.map((s) => s.selected)])
        .toString();

    return prevFiltersString !== filtersString;
};

const mergePrevFilters = function (prevFilters, filters) {
    const prevFiltersIds = prevFilters.reduce((acc, i) => {
        return { ...acc, [i.id]: true };
    }, {});
    const newFilters = filters.reduce((acc, i) => {
        return { ...acc, [i.id]: i };
    }, {});
    const reversedFilters = [...filters].reverse();

    const combinedFiltersIds = reversedFilters.reduce((acc, newFilter, index) => {
        if (prevFiltersIds[newFilter.id]) return acc;

        let nextFilterId = null;
        reversedFilters.slice(index + 1).some((nextFilter) => {
            if (prevFiltersIds[nextFilter.id]) {
                nextFilterId = nextFilter.id;
                return true;
            }
        });
        if (nextFilterId === null) return [{ ...newFilter, status: FILTER_ADDED }, ...acc];
        //findIndex not supported in IE, achieve something similar using map and indexOf
        const insertIndex = acc.map((i) => i.id).indexOf(nextFilterId) + 1;
        return [...acc.slice(0, insertIndex), { ...newFilter, status: FILTER_ADDED }, ...acc.slice(insertIndex)];
    }, prevFilters);

    const combinedFilters = combinedFiltersIds.map((filter) => {
        if (filter.status) {
            return filter;
        }
        return newFilters[filter.id] || { ...filter, status: FILTER_REMOVED };
    });
    return combinedFilters;
};

/**
 * Processes all filters to determine the last *not* MULTI_SELECT that
 * is present in facetConfig (defaultActiveFilterIds), and returns its id
 * @param {array} filters
 * @param {array} defaultActiveFilterIds
 * @returns {string}
 */
const getDefaultActiveFilter = (filters = [], defaultActiveFilterIds) => {
    if (filters.length === 0 || !defaultActiveFilterIds) {
        return;
    }
    const defaultFilter = filters
        .filter((filter) => filter.status !== FILTER_REMOVED)
        .reduce((accumlator, filter) => {
            return defaultActiveFilterIds.includes(filter.queryKey) && filter.type !== 'MULTI_SELECT' ? filter : accumlator;
        });

    return defaultFilter?.id;
};

/**
 * finds the first filter after the passed one (lastIndex) that wasn't removed (status !== FILTER_REMOVED)
 * @param {array} filters
 * @param {int} lastIndex
 * @returns {Object|undefined}
 */
const getNextValidFilter = (filters, lastIndex) => {
    return filters?.find((filter, idx) => {
        return idx > lastIndex && filter.status !== FILTER_REMOVED && (filter.queryKey === 'addCategoryId' || filter.queryKey === 'categoryId');
    });
};
export { filtersNotEqual, mergePrevFilters, getDefaultActiveFilter, getNextValidFilter, FILTER_ADDED, FILTER_REMOVED, CATEGORY_FILTER_ID };
