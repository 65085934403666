import React, { useEffect } from 'react';
import BEMHelper from '@ynap/bem-helper';
import PropTypes from 'prop-types';
import { Button } from '@ynap/button';
import { redirectToLogin } from '@ynap/redirect-utils';
import track from 'react-tracking';
import { withAnalytics } from '@ynap/analytics';
import { trackFavouriteDesignerSignIn } from '../../analytics';

const bem = new BEMHelper('SignIn');

const SignIn = ({ messages, onClose, locale, redirect, designer, tracking, analytics }) => {
    const { favouriteDesigners } = messages || {};
    const { signIn } = favouriteDesigners || {};
    const { title = () => '', text = () => '', cta = () => '' } = signIn || {};

    useEffect(() => tracking?.trackEvent(trackFavouriteDesignerSignIn(analytics, [designer, 'SIGN_IN_DISPLAY'])));

    return (
        <aside className={bem()}>
            {onClose && (
                <button
                    className={bem('close')}
                    type="button"
                    aria-label={'close'}
                    onClick={() => {
                        onClose();
                        tracking?.trackEvent(trackFavouriteDesignerSignIn(analytics, [designer, 'SIGN_IN_CLOSE']));
                    }}
                />
            )}
            {title && <h3 className={bem('title')}>{title()}</h3>}
            <div className={bem('copy')}>{text()}</div>
            <Button
                type="primary"
                onClick={() => {
                    tracking?.trackEvent(trackFavouriteDesignerSignIn(analytics, [designer, 'SIGN_IN_ACCEPT']));
                    redirectToLogin(locale, { redirect });
                }}
                className={bem('button')}
            >
                {cta()}
            </Button>
        </aside>
    );
};

SignIn.propTypes = {
    messages: PropTypes.object,
    onClose: PropTypes.func,
};

SignIn.defaultProps = {
    header: null,
};

export default withAnalytics(track()(SignIn));
