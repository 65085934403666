import React from 'react';
import { Link } from 'react-router-dom';
import BEMHelper from '@ynap/bem-helper';
import { ProductSummary } from '@ynap/product-summary';
import { useAppState } from '@ynap/immutables-provider';

const bem = new BEMHelper('SearchRecommendationsProducts');

const SearchRecommendationsProduct = ({ recommendedProduct, messages }) => {
    if (!recommendedProduct || Object.keys(recommendedProduct).length === 0) return null;
    const { imgSrc, designerName, name, partNumber, href, price, badge, consideredBadge } = recommendedProduct;
    const { locale } = useAppState();

    return (
        <Link className={bem('product')} to={href}>
            <ProductSummary
                product={{
                    imgSrc,
                    designerName,
                    name,
                    partNumber,
                    href,
                    price,
                    badge,
                    onSale: false,
                    consideredBadge,
                }}
                imageSrcsetSize="(max-width: 1024px) 40vw, 20vw"
                messages={messages}
                modifier={'pdpRecommendations'}
                nameModifier={'oneLine'}
                locale={locale}
            />
        </Link>
    );
};

SearchRecommendationsProduct.defaultProps = {
    recommendedProduct: {},
    messages: {},
};

export default SearchRecommendationsProduct;
