import { constructUrl } from '@ynap/url-configuration';

// match.params.SEO_KEYWORDS is encoded by react router so we need to decode here in case it's in Arabic/Chinese
export const getSeoRedirect = (pageType, appLoading, selectedCategorySeoURLKeyword, SEO_KEYWORDS, locale, plpUrlTemplate) =>
    pageType !== 'SEARCH' &&
    !appLoading &&
    selectedCategorySeoURLKeyword &&
    decodeURIComponent(`/${SEO_KEYWORDS}`) !== decodeURIComponent(selectedCategorySeoURLKeyword)
        ? constructRedirectUrl(selectedCategorySeoURLKeyword, locale, plpUrlTemplate)
        : '';

export const getSearchRedirect = (response, locale, state, plpUrlTemplate) =>
    response.body?.redirectLandingPage
        ? checkSearchRedirect(response.body.redirectLandingPage, locale, state?.config?.head?.seo?.baseUrl)
        : constructRedirectUrl(response.body?.redirectURLKeyword?.seo?.seoURLKeyword, locale, plpUrlTemplate);

// If sale is closed redirect to top level for sale closed content
export const getSaleRedirect = (pageType, isPageVisible, SEO_KEYWORDS, primaryCategorySeoURLKeyword, locale, plpUrlTemplate) =>
    pageType === 'MARK_DOWN' &&
    !isPageVisible &&
    `/${SEO_KEYWORDS}` !== primaryCategorySeoURLKeyword &&
    constructRedirectUrl(primaryCategorySeoURLKeyword, locale, plpUrlTemplate);

//set redirect and redirectUrl
export const checkRedirect = (redirectUrl, status) => {
    return redirectUrl && status >= 300 && status <= 399 ? true : false;
};

//3xx redirects
//client side fetch doesnt have access to location header
//proposal to make it accessible: https://github.com/whatwg/fetch/issues/601
export const clientSideRedirect = (history, selectedCategories, redirected, locale, plpUrlTemplate, loadingRequest, prevLoadingRequest) => {
    const selectedCategorySeoURLKeyword = selectedCategories?.[selectedCategories.length - 1]?.seoURLKeyword;

    //redirects when seoURLKeyword in response and url doesn't match
    if (redirected && loadingRequest && !prevLoadingRequest && history) {
        const redirectUrl = `${constructUrl(plpUrlTemplate, {
            LOCALE: locale,
            SEO_URLKEYWORD: selectedCategorySeoURLKeyword,
        })}`;
        history.replace(redirectUrl, {});
    }
};

//TODO: move to locer: https://jira.nap/browse/ISPW-402
//redirect /Designers/wawa_woo -> /designer/wawa-woo
export const checkLegacyRedirect = (legacyRedirectConfig, location) => {
    let legacyRedirect = false,
        legacyRedirectUrl;
    if (legacyRedirectConfig) {
        if (location?.pathname?.toLowerCase()?.includes('/designers/') || location?.pathname?.toLowerCase()?.includes('_')) {
            legacyRedirect = true;
            legacyRedirectUrl = location?.pathname?.toLowerCase()?.replace('/designers/', '/designer/').replace(/_/g, '-');
        }
    }

    return {
        legacyRedirect,
        legacyRedirectUrl,
    };
};

export const checkSearchRedirect = (redirect, locale, baseUrl) => {
    if (!redirect || !locale || !baseUrl) {
        return null;
    }
    try {
        if (redirect.startsWith('https') && !redirect.includes(baseUrl)) {
            // external domain passed as `redirect`
            return redirect;
        }
        // Strip domain/locale and localise
        const { pathname } = new URL(redirect, baseUrl);
        const [, unLocaledPath] = pathname.match(/(?:\/\w{2}-\w{2})?(\/.*)/);

        return `/${locale}${unLocaledPath}`;
    } catch (e) {
        // We do not have client side (SPA) search links, so all traffic should be SSR.
        // Think this is an extreme edge case for IE11 / malformed URLs, better to show search listing.
        return null;
    }
};

export const constructRedirectUrl = (seoURLKeyword, locale, plpUrlTemplate) => {
    if (!seoURLKeyword || !locale || !plpUrlTemplate) {
        return null;
    }
    return constructUrl(plpUrlTemplate, {
        LOCALE: locale,
        SEO_URLKEYWORD: seoURLKeyword,
    });
};
