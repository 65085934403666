import React, { useState } from 'react';
import BEMHelper from '@ynap/bem-helper';
import { Button } from '@ynap/button';
import Overlay from '@ynap/overlay';
import { Notification } from '@ynap/notification';

const bem = new BEMHelper('PLPNotify');

export default function PLPNotify(props) {
    const { messages, message, title } = props;

    const [showOverlay, setShowOverlay] = useState(true);

    const onClose = () => {
        setShowOverlay(!showOverlay);
    };

    if (props.type === 'popup-error') {
        return <Notification {...props} />;
    }

    return (
        <Overlay className={bem('cover')} title={title} onClose={onClose} handleBodyClick={onClose}>
            <p className={bem('content')}>{message}</p>
            <div className={bem('wrapper')}>
                <Button type="link" className={bem('primary-link')}>
                    {messages.productListing.continueLink()}
                </Button>
            </div>
        </Overlay>
    );
}
