import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { withOptimizely } from '@ynap/optimizely-utils';
import SearchRecommendationsProduct from './SearchRecommendationsProduct';

const bem = new BEMHelper('SearchRecommendationsProducts');

const SearchRecommendationsProductList = ({ recommendations, messages, optimizely }) => {
    const recommendationsVisible = recommendations && recommendations?.length > 0;
    const modifiers = [recommendationsVisible ? 'showRecommendations' : 'hideRecommendations'];

    return (
        <div className={bem(null, modifiers)}>
            <div className={bem('topDivider')}>
                <h2 className={bem('dividerHeading')}>{messages?.noResultsSearchPage?.dividerHeading()}</h2>
            </div>
            <section className={bem('sectionWrapper')}>
                <div className={bem('carouselMargin')}>
                    <div className={bem('carouselMarginSides')}>
                        <div className={bem('slidesWrapper')}>
                            {recommendations.map((recommendedProduct) => {
                                return (
                                    <SearchRecommendationsProduct
                                        recommendedProduct={recommendedProduct}
                                        messages={messages}
                                        key={recommendedProduct && recommendedProduct.href}
                                        optimizely={optimizely}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

SearchRecommendationsProductList.propTypes = {
    recommendations: PropTypes.array,
    messages: PropTypes.shape({
        wearItWith: PropTypes.shape({
            heading: PropTypes.func.isRequired,
        }).isRequired,
        youMayAlsoLike: PropTypes.shape({
            heading: PropTypes.func.isRequired,
        }).isRequired,
    }),
    heading: PropTypes.string,
    dividerHeading: PropTypes.string,
    tracking: PropTypes.func.isRequired,
    isFullWidth: PropTypes.bool,
    withQuickAdd: PropTypes.bool,
    optimizely: PropTypes.shape({
        track: PropTypes.func.isRequired,
    }),
};

SearchRecommendationsProductList.defaultProps = {
    recommendations: [],
    messages: {},
    heading: '',
};

export default withOptimizely(SearchRecommendationsProductList);
