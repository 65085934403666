export const moveToTop = (node) => {
    const { parentNode } = getWrapperNode(node);
    const stickyPosition = getStickyPosition(node);

    parentNode.scrollBy({ top: stickyPosition, behavior: 'smooth' });
};

export const getListHeight = (node) => {
    const { parentHeight } = getWrapperNode(node);
    return parentHeight;
};

const getTopPosition = (node) => {
    if (!node) return null;

    const { top } = node.getBoundingClientRect();

    return {
        nodeTopPosition: top,
    };
};

const getWrapperNode = (node) => {
    const parentNode = node.closest('.content');
    if (!parentNode) return null;

    const { top, height } = parentNode.getBoundingClientRect();

    return {
        parentNode,
        parentTopPosition: top,
        parentHeight: height - parentNode.offsetTop,
    };
};

export const getStickyPosition = (node) => {
    const { nodeTopPosition } = getTopPosition(node);
    const { parentTopPosition } = getWrapperNode(node);
    const stickyPosition = nodeTopPosition - parentTopPosition;

    return stickyPosition;
};
