import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import Filter from '../../Filter/Filter';

const bem = new BEMHelper('DesignerFilterGroup');

const TopDesignersFilter = ({ filter, generateListingUrl, trackFilterEvent, numOfTopDesigners, title, useIdentifier }) => {
    const [topDesigners, setTopDesigners] = useState(null);
    const [multiSelectedCategory] = useState([]);

    const getTopDesigners = (values, numOfTopDesigners) => {
        if (values.length <= parseInt(numOfTopDesigners)) return null;
        return [...values].sort((a, b) => b.count - a.count).slice(0, numOfTopDesigners);
    };

    useEffect(() => {
        const { values } = filter;
        setTopDesigners(getTopDesigners(values, numOfTopDesigners));
    }, [filter, numOfTopDesigners]);

    return (
        <>
            {topDesigners && (
                <div className={bem('topDesigners')}>
                    <span className={bem('title')}>{title}</span>
                    <>
                        {topDesigners.map((filterValue) => (
                            <div key={filterValue.value}>
                                <Filter
                                    filter={filterValue}
                                    link={generateListingUrl({
                                        toggleParams: { [filter.queryKey]: filterValue.value },
                                        clearParamsByKey: ['pageNumber'],
                                    })}
                                    className={'checkBox'}
                                    key={filterValue.value}
                                    trackFilterEvent={trackFilterEvent}
                                    parentLabel={useIdentifier ? filter.id : filter.label}
                                    subcategoryLabel={'Top Designers'}
                                    multiSelectedCategory={multiSelectedCategory}
                                />
                            </div>
                        ))}
                    </>
                </div>
            )}
        </>
    );
};

TopDesignersFilter.propTypes = {
    filter: PropTypes.array.isRequired,
    generateListingUrl: PropTypes.func.isRequired,
    trackFilterEvent: PropTypes.func.isRequired,
    numOfTopDesigners: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};

export default TopDesignersFilter;
