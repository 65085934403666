import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import Advertisement, { DEFAULT_SIZE_MAPPING } from '@ynap/gpt-advertisement';

const FooterAd = ({ item }) => {
    const bem = new BEMHelper('FooterAd');
    const { ynapParameter, layoutVariant } = item;

    const getSizeMapping = (layoutVariant) => {
        if (layoutVariant.includes('restricted-width')) return DEFAULT_SIZE_MAPPING.slice(0, -1);
    };

    return (
        <div className={bem('advertisementWrapper')}>
            <Advertisement path={ynapParameter} className={bem('advertisement')} sizeMapping={getSizeMapping(layoutVariant)} />
        </div>
    );
};

FooterAd.layoutVariant = ['yos-plp-ad', 'yos-plp-ad-restricted-width'];

export default FooterAd;
