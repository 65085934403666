import EmptyList from '@ynap/empty-list';
import React, { useState, useEffect } from 'react';
import BEMHelper from '@ynap/bem-helper';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import Skeleton from '@ynap/skeleton';

const bem = new BEMHelper('PLPEmptyPage');

const PLPEmptyPage = (props) => {
    const { pageType, messages, headerData, emptyListContent, emptyListGoToUrl, locale, generic = false, hasAppliedFilters = false, isPageVisible } = props;
    const [userFetched, setUserFetched] = useState(false);
    const [showEmailForm, setShowEmailForm] = useState(false);

    //for sale close, start with skeleton
    const [fetchingUser, setFetchingUser] = useState(pageType === 'MARK_DOWN');

    if (generic) {
        return <EmptyList className={bem('emptyList')} title={messages.productListing.emptyList({})} variant="productListingPage" />;
    }

    //fetch user data on sale close page
    useEffect(() => {
        // this is for sale is closed page or other pages with email form tag on CM - we need user info to show/hide registration form
        if (window && window.SF && (pageType === 'MARK_DOWN' || emptyListContent?.tags[0]?.name === 'email-form')) {
            setFetchingUser(true);
            window.SF.getUserSummary().then((user) => {
                setFetchingUser(false);
                setUserFetched(true);
                setShowEmailForm(!user);
            });
        }
    }, []);

    const logInStatus = showEmailForm ? 'LOGGED_OUT' : 'LOGGED_IN';

    const saleIsClosedCopy = userFetched
        ? `${messages.productListing.emptyListCopy?.({ pageType: 'MARK_DOWN' })} ${messages.accountUtility?.signupMessage?.({ logInStatus })}`
        : '';
    const searchCopy = messages.productListing.emptyList({});
    let emptyListDetailsCopy = messages.productListing.emptyListCopy?.({ pageType });

    if (emptyListContent) {
        emptyListDetailsCopy = emptyListContent.teaserText;
    } else if (pageType === 'MARK_DOWN') {
        emptyListDetailsCopy = isPageVisible ? searchCopy : saleIsClosedCopy;
    }

    const saleModifier = pageType === 'MARK_DOWN' ? 'sale-ended' : '';
    const designerModifier = pageType === 'DESIGNER' ? 'coming-soon-designer' : '';
    const variant = pageType === 'MARK_DOWN' ? 'saleIsClosed' : 'productListingPage';

    //Add the page type to the message bundle to enable the link on more listing page type
    const emptyListGoTo = messages.productListing.emptyListGoTo({ pageType });
    const showLink = pageType === 'MARK_DOWN' ? emptyListGoTo && !showEmailForm && userFetched : !!emptyListGoTo;

    const linkModifier = pageType === 'DESIGNER' ? 'asButton' : '';

    const renderForm = () => {
        return (
            <>
                {!hasAppliedFilters && showEmailForm && (
                    <section className={bem('registration-form-container')}>
                        <label className={bem('email-label')}>{messages.accountUtility.emailLabel()}</label>
                        <RegistrationForm locale={locale} messages={messages} className={bem('registration-form')} />
                    </section>
                )}

                {!hasAppliedFilters && showLink && (
                    <a className={bem('emptyList-goTo', linkModifier)} href={emptyListGoToUrl || `/${locale}/`}>
                        {emptyListGoTo}
                    </a>
                )}
            </>
        );
    };

    return (
        <EmptyList
            className={bem('emptyList', [saleModifier, designerModifier])}
            title={
                emptyListContent?.teaserTitle ||
                messages.productListing.emptyList({ pageType: !hasAppliedFilters && pageType, query: headerData.title || headerData.subTitle })
            }
            details={!hasAppliedFilters && emptyListDetailsCopy}
            variant={!hasAppliedFilters && variant}
        >
            {fetchingUser ? (
                <>
                    <Skeleton className={bem('copySkeleton')} containerClassName={bem('copySkeletonContainer')} count={3} />
                    <Skeleton className={bem('formSkeleton')} containerClassName={bem('formSkeletonContainer')} count={2} />
                </>
            ) : (
                renderForm()
            )}
        </EmptyList>
    );
};
export default PLPEmptyPage;
