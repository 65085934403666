/**
 * Constructs the full URL using the base URL and the href.
 *
 * @param {Object} config - The state config object.
 * @param {string} href - the relative path.
 * @returns {string} The Full URL.
 */
export const getFullUrl = (config, href) => {
    if (!href) return '';
    const baseUrl = config.head.seo.baseUrl;
    return baseUrl + href;
};

/**
 * Calculates the product price based on the selling price amount and divisor.
 *
 * @param {Object} price - The Price object.
 * @returns {number} The calculated price.
 */
export const calculateProductPrice = (price = {}) => {
    const amount = price.sellingPrice?.amount;
    const divisor = price.sellingPrice?.divisor;

    return (amount / divisor).toFixed(2);
};

/**
 * Determines the variant naming schema for a product based on its buyable colours and SKUs.
 *
 * @param {Object} product - The primary product object for which the variant naming schema is being determined.
 * @returns {string} A string indicating the type of variant naming schema applicable to the product, based on the number of buyable colours and SKUs.
 */
export const getVariantNameSchema = (product) => {
    const buyableProductColours = product?.colourSwatches;

    if (buyableProductColours?.length > 1 && product?.sKUs?.length >= 1) {
        return 'multiColorsSizes';
    }
    if (buyableProductColours?.length === 1 && product?.sKUs?.length > 1) {
        return 'singleColorSizes';
    }

    return 'singleColorSize';
};

/**
 * Determines the appropriate schema properties for a product based on its variants.
 *
 * @param {Object} product - The primary product object for which the schema properties are being determined.
 * @returns {Array} An array of schema.org URLs that correspond to the product's variant properties, such as size and color.
 */
export const getVariantSchema = (product) => {
    const variantNameSchema = getVariantNameSchema(product);

    switch (variantNameSchema) {
        case 'multiColorsSizes':
            return ['https://schema.org/size', 'https://schema.org/color'];
        case 'singleColorSizes':
            return ['https://schema.org/size'];
        default:
            return [];
    }
};

/**
 * Generates a descriptive string for a product group, detailing available colours and sizes.
 *
 * @param {Object} product - The primary product object for which the group description is being generated.
 * @param {Object} messages - The messages object containing the translations for the current locale.
 * @returns {string} A descriptive string for the product group, highlighting the available colours, sizes, and material composition.
 */
export const getProductGroupDescriptionSchema = (product, messages) => {
    const productColours = product?.colourSwatches || '';
    const productName = product?.name;
    const composition = product?.sKUs[0]?.composition;
    const translation = messages?.seo?.pdpMarkup;

    const color1 = productColours[0]?.label?.toLowerCase();
    const color2 = productColours[1]?.label?.toLowerCase();

    const colorDescription = `${translation.nbColours({ copy: productColours.length, color1, color2 })}`;

    return `${productName}${colorDescription} ${composition}`.trim();
};

/**
 * Constructs a product description schema.
 * This function generates a descriptive string for a product SKU, incorporating details such as color, size, and material. It prioritizes the colour's editorial description if available; otherwise, it defaults to a generic description format.
 *
 * @param {Object} product - The primary product object.
 * @param {Object} sku - The specific SKU of the product being described.
 * @param {string} skuSize - The size of the SKU.
 * @param {Object} messages - The messages object containing the translations for the current locale.
 * @returns {string} A descriptive string for the product SKU, formatted for SEO optimization.
 */
export const getProductDescriptionSchema = (product, sku, skuSize, messages) => {
    const translation = messages?.seo?.pdpMarkup;
    const color = product?.label.toLowerCase();

    return translation.productDescription({ name: product?.name, color, size: skuSize, material: sku?.composition });
};
