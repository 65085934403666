const getFounditPageTemplate = (pageType) => {
    switch (pageType) {
        case 'listing page':
            return 'Category';
        case 'product details page':
            return 'Product';
        case 'search results page':
            return 'Search';
        default:
            return 'Category';
    }
};

const getFounditLinkContainer = (pageType) => {
    if (pageType === 'product details page') return 'productwidget-product';
    return 'Main-Links';
};

const getFounditSubCategory = (item, linkPosition, totalLinks, pageType) => {
    const { title, href: targetPage } = item;
    const sourcePage = window?.location?.href;
    const pageTemplate = getFounditPageTemplate(pageType);
    const linkContainer = getFounditLinkContainer(pageType);

    return [sourcePage, pageTemplate, linkContainer, linkPosition, totalLinks, title, targetPage].join('|');
};

export const trackFounditModuleClick = ({ analytics, item, totalLinks, linkPosition }) => ({
    event: 'links-click',
    effect: 'click on Found it link-clicks',
    category: {
        primaryCategory: 'FoundIt',
        subCategory: getFounditSubCategory(item, linkPosition, totalLinks, analytics.page?.category?.pageType),
    },
});
